import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as Yup from "yup";
import { HighlightHmdk } from "./../../../../lib/highlight-hmdk";

import { encodeUrl } from "../../../../lib/encode-url";
import axios from "axios";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Arrow from "../../../icons/arrow";

const channelLabels = {
  gdf: "Gesellschaft der Freunde",
  kv: "Konzertvermittlung",
  wt: "Wilhelma Theater",
  hmdk: "Hochschule",
  career_service: "Career Service",
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("E-Mail ist ein Pflichtfeld."),
  consent: Yup.bool()
    .nullable()
    .oneOf([true], "Sie müssen den Bedingungen zustimmen."),
  channels: Yup.array().min(1, "Sie müssen mindestens ein Thema auswählen."),
});

const NewsletterRegistration = ({ content }) => {
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorPath, setErrorPath] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const channels = content.fieldSelectableChannels.map((channel) => ({
    name: channel.targetId,
    label: channelLabels[channel.targetId],
  }));

  const onSubmit = (e) => {
    e.preventDefault();
    setError(false);
    setErrorMsg("");
    setErrorPath("");
    setSuccess(false);

    const formData = new FormData(e.target);

    const values = {
      email: formData.get("email"),
      consent: formData.get("consent") === "on",
      channels: formData.getAll("channels[]"),
    };

    if (channels === 1) {
      values.channels = [content.fieldSelectableChannels[0].targetId];
    }

    // validate
    try {
      validationSchema.validateSync(values);
    } catch (error) {
      console.error(error);
      setError(true);
      setErrorMsg(error.message);
      setErrorPath(error.path);
      return;
    }

    setLoading(true);

    /* axios
      .post("/api/newsletter/subscribe", {
        email: values.email,
        newsletterId: selectedChannels,
      })
      .then((response) => {
        console.info(response);
        setSuccess(true);
        setSuccessMsg("Sie wurden in den Newsletter eingetragen.");
      })
      .catch((error) => {
        console.error(error);
        setError(true);
        setErrorMsg("Es ist ein Fehler aufgetreten.");
      })
      .finally(() => {
        setLoading(false);
        actions.setSubmitting(false);
      }); */
  };

  const newsletterRegistrationClassNames = classNames({
    "newsletter-register-block": true,
    "including-name": content.fieldHeading.includes("HMDK Stuttgart"),
  });

  return (
    <div className={newsletterRegistrationClassNames}>
      {/* @todo: Messages as overlay? */}
      <div className="message-block">
        {error && !errorMsg && (
          <div className="alert alert-danger" role="region" aria-live="polite">
            Es ist ein Fehler aufgetreten.
          </div>
        )}

        {error && errorMsg && !errorPath && (
          <div className="alert alert-danger" role="region" aria-live="polite">
            {errorMsg}
          </div>
        )}

        {success && successMsg && (
          <div className="alert alert-success" role="region" aria-live="polite">
            {successMsg}
          </div>
        )}
      </div>

      <h3 className="title" id={encodeUrl(content.fieldHeading)}>
        <HighlightHmdk text={content.fieldHeading} />
      </h3>

      {content.fieldText?.processed && (
        <div
          className="text"
          dangerouslySetInnerHTML={{ __html: content.fieldText?.processed }}
        />
      )}

      <form onSubmit={onSubmit}>
        <div className="form-row">
          <div className="col-16 form-group">
            {channels.length > 1 && (
              <Accordion
                allowMultipleExpanded={true}
                allowZeroExpanded={true}
                className="select-channels"
              >
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Thema auswählen
                      <Arrow orientation={"bottom"} />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div>
                      {channels.map((channel) => (
                        <div className="form-check" key={channel.name}>
                          <input
                            type="checkbox"
                            name="channels[]"
                            value={channel.name}
                            id={channel.name}
                          />
                          <label htmlFor={channel.name}>{channel.label}</label>
                        </div>
                      ))}
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            )}
            {errorMsg && errorPath === "channels" && (
              <div
                className="form-error text-danger"
                role="region"
                aria-live="polite"
              >
                {errorMsg}
              </div>
            )}

            <label htmlFor="email" className="sr-only">
              Ihre E-Mail-Adresse
            </label>
            <input
              aria-label="Ihre E-Mail-Adresse"
              type="email"
              name="email"
              id="email"
              className="form-control"
              placeholder="E-Mail"
            />
            {errorMsg && errorPath === "email" && (
              <div
                className="form-error text-danger"
                role="region"
                aria-live="polite"
              >
                {errorMsg}
              </div>
            )}
          </div>

          <div className="col-16 form-check">
            <input
              type="checkbox"
              name="consent"
              id="consent"
              aria-labelledby="form-check-label"
              className="form-check-input"
            />
            <label className="form-check-label" htmlFor="consent">
              Ja, ich bin einverstanden. Dieses Einverständnis kann ich
              jederzeit widerrufen.
              {errorMsg && errorPath === "consent" && (
                <div
                  className="form-error text-danger"
                  role="region"
                  aria-live="polite"
                >
                  {errorMsg}
                </div>
              )}
            </label>
          </div>
        </div>

        <div className="form-row actions">
          <div className="col-16 d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              Newsletter bestellen
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

NewsletterRegistration.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    __typename: PropTypes.string,
    entityBundle: PropTypes.string,
    entityId: PropTypes.string,
    fieldText: PropTypes.shape({
      __typename: PropTypes.string,
      value: PropTypes.string,
      processed: PropTypes.string,
    }),
    fieldSelectableChannels: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.string,
        entity: PropTypes.shape({
          entityLabel: PropTypes.string,
        }),
      })
    ),
  }),
};

export default NewsletterRegistration;
