import React, { Component } from "react";
import {self} from "../../config";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

const Arrow = (props) => {
  const { orientation, width = "2" } = props;
  const isMobile = useSelector((state) => state.appStore.isMobile);

  let strokeWidth = width;

  if (isMobile) {
    strokeWidth = (width === "1") ? "0.75" : "1";
  }
  return (
    <div
      role={props.role}
      onClick={props.onClick}
      className={`arrow-wrapper standard ${orientation}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="33"
        fill="none"
        viewBox="0 0 32 33"
        vectorEffect="non-scaling-stroke"
      >
        <path
          stroke="#000"
          strokeWidth={strokeWidth}
          vectorEffect="non-scaling-stroke"
          d="M16 0v32M1 17l15 14.5L30.5 17"
        />
      </svg>
    </div>
  );
}

export default Arrow;
