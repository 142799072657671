import React from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";

import EditButton from "../../../backend/edit-button";
import {FormattedMessage} from "react-intl";
import FieldTime from "../../date-time/field-time";
import ErrorBoundary from "../../../error-boundary";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";
import Arrow from "../../icons/arrow";
import ContentBase from "../../content-base/content-base";
import CustomHyphenation from "../../custom-hyphenation";
import CheckForChildren from "../../../lib/check-for-children";

const TeaserLecture = ({ item }) => {
  return (
    <tr className="node node-teaser teaser-lecture row">
      {/*<EditButton
        adminApp={this.props.adminApp}
        entityId={this.props.itemId}
        destinationRoute={this.props.location.patdname}
      />*/}
      <td className="title-col col-16 col-md-6">
        <h5 className={"title"}>
          <CustomHyphenation length={8}>
            {item?.entityLabel}
          </CustomHyphenation>
        </h5>

        {item?.fieldSubtitle &&
          <h6 className={"subtitle"}>
            {item.fieldSubtitle}
          </h6>
        }

        {item?.fieldLectureType &&
          <span className={"lecture-type multiple-values"}>
            {item?.fieldLectureType && item?.fieldLectureType.map((lectureType, lectureTypeIndex) =>
              <div className="lecture-type" key={`${lectureType}-${lectureTypeIndex}`}>
                {lectureType.entity.name}
              </div>
            )}
          </span>
        }
      </td>

      <td className="day col-16 col-md-2 multiple-values">
        <span className="mobile-label">
          <FormattedMessage id={"lectureheader.day"}/>
        </span>
        {item?.fieldDays && item?.fieldDays.map((day, dayIndex) =>
          <div className="day" key={`${day}-${dayIndex}`}>
            {day}
          </div>
        )}
      </td>

      <td className="time col-16 col-md-2 multiple-values">
        <span className="mobile-label">
          <FormattedMessage id={"lectureheader.time"}/>
        </span>

        {item?.fieldTimes && item?.fieldTimes.map((time, timeIndex) =>
          <div className="time" key={`${time}-${timeIndex}`}>
            {time}
          </div>
        )}
      </td>

      <td className="location col-16 col-md-2">
        <span className="mobile-label">
          <FormattedMessage id={"lectureheader.location"}/>
        </span>

        {item?.fieldRoomOrOnline}
      </td>

      <td className="lp col-16 col-md-1">
        {item?.fieldCreditPoints &&
          <>
            <span className="mobile-label">
              <FormattedMessage id={"lectureheader.lp"}/>
            </span>

            {item?.fieldCreditPoints}
          </>
        }
      </td>

      <td className="lecturer col-16 col-md-3 multiple-values">
        {(item?.fieldInternalLecturer?.length > 0 || item?.fieldExternalLecturer?.length > 0) && (
          <span className="mobile-label">
            <FormattedMessage id={"lectureheader.lecturer"}/>
          </span>
        )}

        {item?.fieldInternalLecturer && item?.fieldInternalLecturer.map((internalLecturer, internalLecturerIndex) =>
          <Link to={internalLecturer?.entity?.entityUrl?.path} className="lecturer internal-link" key={`${internalLecturer?.entity?.entityLabel}-${internalLecturerIndex}`}>
            {internalLecturer?.entity?.entityLabel}
          </Link>
        )}
        {item?.fieldExternalLecturer && item?.fieldExternalLecturer.map((externalLecturer, externalLecturerIndex) =>
          <div className="lecturer" key={`${externalLecturer}-${externalLecturerIndex}`}>
            {externalLecturer}
          </div>
        )}
      </td>

      <CheckForChildren
        classes={"col-16 extra-infos"}
        ElementType={"td"}
      >
        {item.fieldStartingFromText &&
          <div className="d-flex flex-wrap">
            <span className="label">
              <FormattedMessage id={"lecture.startingFrom"}/>
            </span>
            <div className="starting-from">
              {item.fieldStartingFromText}
            </div>
          </div>
        }
        {(item.fieldRegistrationBy || item.fieldRegistrationAt) &&
          <div className="multiple-values">
            <div className="d-flex flex-wrap">
              <span className="label">
              <FormattedMessage id={"lecture.registration"}/>
            </span>

              {item.fieldRegistrationBy &&
                <div className="registration-by">
                  {item.fieldRegistrationBy}
                </div>
              }

              {item.fieldRegistrationAt &&
                <div
                  className="text registration-at"
                  dangerouslySetInnerHTML={{ __html: item.fieldRegistrationAt.processed }}
                />
              }
            </div>
          </div>
        }
        {item.fieldOpenFor &&
          <div className="open-for">
            <span className="label">
              <FormattedMessage id={"lecture.openFor"}/>
            </span>
            {item.fieldOpenFor}
          </div>
        }
        {item.fieldParticipantAmmount &&
          <div className={"participant-ammount"}>
            <span className="label">
              <FormattedMessage id={"lecture.participantAmmount"}/>
            </span>
            {item.fieldParticipantAmmount}
          </div>
        }
        {item.fieldText &&
          <div
            className="text free"
            dangerouslySetInnerHTML={{ __html: item.fieldText.processed }}
          />
        }
        {item.fieldExtraText &&
          <Accordion
            allowMultipleExpanded={true}
            allowZeroExpanded={true}
          >
            <ErrorBoundary>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <FormattedMessage id={"more"}/>
                    <div className="arrow">
                      ↓
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div
                    className="text extra"
                    dangerouslySetInnerHTML={{ __html: item.fieldExtraText.processed }}
                  />
                </AccordionItemPanel>
              </AccordionItem>
            </ErrorBoundary>
          </Accordion>
        }
      </CheckForChildren>

    </tr>
  );
}

export const teaserLecturePropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  entityUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
  publishedAt: PropTypes.shape({
    value: PropTypes.number,
    publishedAtOrNow: PropTypes.number,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldTeasertext: PropTypes.shape({
    processed: PropTypes.string,
  }),
  fieldBild: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
});

TeaserLecture.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserLecturePropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default withRouter(TeaserLecture);
