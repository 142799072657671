import React from "react";
import {useSelector} from "react-redux";

const Download = () => {
  const isMobile = useSelector((state) => state.appStore.isMobile);

  let strokeWidth = 2;

  if (isMobile) {
    strokeWidth = 1;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.97"
      height="42"
      fill="none"
      version="1.1"
      viewBox="0 0 35 42"
      className="download-icon"
      vectorEffect="non-scaling-stroke"
    >
      <path fill="none" d="M-15.515 -11H48.485V53H-15.515z" vectorEffect="non-scaling-stroke"></path>
      <path stroke="#000" strokeWidth={strokeWidth} d="M0 41h32.97" vectorEffect="non-scaling-stroke"></path>
      <path stroke="#000" strokeWidth={strokeWidth} d="M16.485 0v32" vectorEffect="non-scaling-stroke"></path>
      <path
        stroke="#000"
        strokeWidth={strokeWidth}
        d="M1.94 17l14.545 14.5L30.545 17"
        vectorEffect="non-scaling-stroke"
      ></path>
    </svg>
  );
}

export default Download;