  import React, { Component } from "react";
import PropTypes from "prop-types";

import ParagraphHeroText from "./paragraphs/hero-text/paragraph-hero-text";
import ContentCloud from "./paragraphs/content-cloud/paragraph-content-cloud";
import ParagraphTextAndSpeech from "./paragraphs/text-and-speech/paragraph-text-and-speech";
import ParagraphClickSuggestions from "./paragraphs/click-suggestions/paragraph-click-suggestions";
import ParagraphTeaserList from "./paragraphs/teaserliste/paragraph-teaser-list";
import ParagraphTeaserImage from "./paragraphs/teaser-image/paragraph-teaser-image";
import ParagraphSectionheading from "./paragraphs/section-heading/paragraph-sectionheading";
import ParagraphText from "./paragraphs/text/paragraph-text";
import Eforkbot from "./../../eforkbot/eforkbot";
import ParagraphBilderSlider from "./paragraphs/image-image-slider/paragraph-bilder-slider";
import ParagraphNumberFacts from "./paragraphs/number-facts/paragraph-number-facts";
import ParagraphTeaserSlider from "./paragraphs/teaser-slider/paragraph-teaser-slider";
import ParagraphNewsletteranmeldung from "./paragraphs/newsletter/paragraph-newsletteranmeldung";
import ParagraphAkkordionText from "./paragraphs/akkordion-text/paragraph-akkordion-text";
import ParagraphDossier from "./paragraphs/dossier/paragraph-dossier";
import ParagraphMedia from "./paragraphs/media-video-audio/paragraph-media";
import ParagraphPageTitle from "./paragraphs/page-title/paragraph-page-title";
import ParagraphCallToAction from "./paragraphs/cta/paragraph-call-to-action";
import ParagraphExtendedTeaserOverview from "./paragraphs/extended-teaser-list/paragraph-extended-teaser-overview";
import ParagraphCalendarInformation from "./paragraphs/kalender-information/paragraph-calendar-information";
import ParagraphDownload from "./paragraphs/download/paragraph-download";
import ParagraphZitat from "./paragraphs/zitat/paragraph-zitat";
import ParagraphQuoteCarousel from "./paragraphs/quote-carousel/paragraph-quote-carousel";
import ParagraphDownloadSchublade from "./paragraphs/download-schublade/paragraph-download-schublade";
import ParagraphTimeline from "./paragraphs/timeline/paragraph-timeline";
import ParagraphKontakt from "./paragraphs/kontakt/paragraph-kontakt";
import ParagraphStrukturierteListe from "./paragraphs/strukturierte-liste/paragraph-strukturierte-liste";
import ParagraphPersonenInformationen from "./paragraphs/personen-informationen/paragraph-personen-informationen";
import ParagraphKalenderEinzelDoppel from "./paragraphs/kalender-einzel-doppel/paragraph-kalender-einzel-doppel";
import ParagraphKalenderAbschnitt from "./paragraphs/kalender-abschnitt/paragraph-kalender-abschnitt";
import ParagraphKalenderMonat from "./paragraphs/kalender-monat/paragraph-kalender-monat";
import ParagraphAudio from "./paragraphs/audio/paragraph-audio";
import ParagraphLogosPartners from "./paragraphs/logos-partners/logos-partners";
import ParagraphTeaserListEvents from "./paragraphs/teaserliste/events/paragraph-teaser-list-events";
import ParagraphFormular from "./paragraphs/webform/paragraph-formular";
import TeeaserOverviewMulti from "./paragraphs/teaser-overview-multi/teaser-overview-multi";
import ParagraphPinboard from "./paragraphs/pinboard/paragraph-pinboard";
import ParagraphArtistOverview from "./paragraphs/artist-overview/paragraph-artist-overview";
import ParagraphInfoscreenSlider from "./paragraphs/infoscreen-slider/infoscreen-slider";

import ErrorBoundary from "../../error-boundary";
import ParagraphTeaserListPages from "./paragraphs/teaserliste/pages/paragraph-teaser-list-pages";
import ParagraphExtendedTeaserOverviewProjects from "./paragraphs/extended-teaser-list/projects/paragraph-extended-teaser-overview-projects";
import ParagraphScheduledModule from "./paragraphs/scheduled-module/paragraph-scheduled-module";
import ParagraphBite from "./paragraphs/bite/paragraph-bite";
import ParagraphExtendedTeaserOverviewPersons from "./paragraphs/extended-teaser-list/persons/paragraph-extended-teaser-overview-persons";
import InPageNavigation from "../in-page-navigation/in-page-navigation";
import ParagraphSocialMediaInfo from "./paragraphs/social-media-info/paragraph-social-media-info";
import {connect} from "react-redux";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp
});

class ContentBase extends Component {
  render() {
    return (
      <section className={`content-wrap ${this.props.className}`}>
        {this.props.content.map((item, index) => (
          <ErrorBoundary key={index}>
            {/* Only Show Paragraphs when looged in or status is published */}
            {(!!item.entity?.status || this.props.adminApp) &&
              <div
                className={`module-wrapper ${item.entity.entityBundle}`}
                data-color-scheme={
                  item.entity.fieldColorScheme
                    ? item.entity.fieldColorScheme.entity.fieldColorVariable
                    : "default"
                }
                data-publish-status={item.entity.status}
              >
                {
                  // eslint-disable-next-line complexity
                  (() => {
                    let paragraphItem = item.entity,
                      properties = {
                        content: paragraphItem,
                        viewMode: this.props.viewMode,
                        // Submit Title or external title to every module in case it is needed. Definitely needed in Page Title Module
                        title: this.props.node?.fieldDisplayedTitle || this.props.node?.entityLabel
                      };

                    if (paragraphItem.entityBundle === "from_library") {
                      paragraphItem =
                        item.entity.fieldReusableParagraph.entity.paragraphs
                          .entity;
                      properties.content = paragraphItem;
                    }

                    switch (paragraphItem.entityBundle) {
                      case "content_cloud":
                        return <ContentCloud {...properties} />;
                      case "text_and_speech":
                        return <ParagraphTextAndSpeech {...properties} />;
                      case "audio":
                        return <ParagraphAudio {...properties} />;
                      case "media":
                        return <ParagraphMedia {...properties} />;
                      case "hero_text":
                        return <ParagraphHeroText {...properties} />;
                      case "click_suggestions":
                        return (
                          <ParagraphClickSuggestions {...properties} />
                        );
                      case "teaserliste":
                      case "teaserliste_news":
                      case "teaserliste_personen":
                      case "teaserlist_artists":
                      case "teaserlist_lectures":
                        return <ParagraphTeaserList {...properties} />;
                      case "teaserliste_events":
                        return (
                          <ParagraphTeaserListEvents {...properties} />
                        );
                      case "teaserliste_pages":
                        return (
                          <ParagraphTeaserListPages {...properties} />
                        );
                      case "teaser_image_text":
                        return <ParagraphTeaserImage {...properties} />;
                      case "section_heading":
                        return (
                          <ParagraphSectionheading {...properties} />
                        );
                      case "text":
                        return <ParagraphText {...properties} />;
                      case "bilder_slider":
                      case "image":
                        return <ParagraphBilderSlider {...properties} />;
                      case "zahlen_und_fakten":
                        return <ParagraphNumberFacts {...properties} />;
                      case "teaser_slider":
                        return <ParagraphTeaserSlider {...properties} />;
                      case "formular":
                        return <ParagraphFormular content={item.entity} />;
                      case "newsletteranmeldung":
                        return (
                          <ParagraphNewsletteranmeldung {...properties} />
                        );
                      case "text_akkordion":
                        return <ParagraphAkkordionText {...properties} />;
                      case "chatbot":
                        return <Eforkbot />;
                      case "teaser_sammlung_dossier_":
                        return <ParagraphDossier {...properties} />;
                      case "seitentitel":
                        return <ParagraphPageTitle {...properties} />;
                      case "call_to_action":
                        return <ParagraphCallToAction {...properties} />;
                      case "erweiterte_teaseruebersicht":
                      case "teaseruebersicht_events": {
                        const nodes = {};

                        if (paragraphItem.fieldManuelleSortierung) {
                          nodes.loading = false;
                          nodes.nodeQuery = {};
                          nodes.nodeQuery.entities =
                            paragraphItem.fieldBeitraege.map(
                              (node) => node.entity
                            );

                          return (
                            <ParagraphExtendedTeaserOverview
                              {...properties}
                              nodes={nodes}
                            />
                          );
                        }

                        return (
                          <ParagraphExtendedTeaserOverview
                            {...properties}
                          />
                        );
                      }
                      case "teaseruebersicht_personen": {
                        const nodes = {};

                        if (paragraphItem.fieldManuelleSortierung) {
                          nodes.loading = false;
                          nodes.nodeQuery = {};
                          nodes.nodeQuery.entities =
                            paragraphItem.fieldBeitraege.map(
                              (node) => node.entity
                            );

                          return (
                            <ParagraphExtendedTeaserOverviewPersons
                              {...properties}
                              nodes={nodes}
                            />
                          );
                        }

                        return (
                          <ParagraphExtendedTeaserOverviewPersons
                            {...properties}
                          />
                        );
                      }
                      case "teaseruebersicht_projekte": {
                        const nodes = {};

                        if (paragraphItem.fieldManuelleSortierung) {
                          nodes.loading = false;
                          nodes.nodeQuery = {};
                          nodes.nodeQuery.entities =
                            paragraphItem.fieldBeitraege.map(
                              (node) => node.entity
                            );

                          return (
                            <ParagraphExtendedTeaserOverviewProjects
                              {...properties}
                              nodes={nodes}
                            />
                          );
                        }

                        return (
                          <ParagraphExtendedTeaserOverviewProjects
                            {...properties}
                          />
                        );
                      }
                      /*case "veranstaltungsinformationen":
                        return (
                          <ParagraphCalendarInformation
                            {...properties}
                            nodeContent={this.props.nodeContent}
                          />
                        );
                      case "personeninformationen":
                        return (
                          <ParagraphPersonenInformationen
                            {...properties}
                            nodeContent={this.props.nodeContent}
                          />
                        );*/
                      case "download":
                        return (
                          <ParagraphDownload
                            {...properties}
                            nodeContent={this.props.nodeContent}
                          />
                        );
                      case "zitat":
                        return (
                          <ParagraphZitat
                            {...properties}
                            nodeContent={this.props.nodeContent}
                          />
                        );
                      case "quote_carousel":
                        return (
                          <ParagraphQuoteCarousel
                            {...properties}
                            nodeContent={this.props.nodeContent}
                          />
                        );
                      case "download_schublade":
                        return (
                          <ParagraphDownloadSchublade
                            {...properties}
                            nodeContent={this.props.nodeContent}
                          />
                        );
                      case "timeline":
                        return (
                          <ParagraphTimeline
                            {...properties}
                            nodeContent={this.props.nodeContent}
                          />
                        );
                      case "kontakt":
                        return (
                          <ParagraphKontakt
                            {...properties}
                            nodeContent={this.props.nodeContent}
                          />
                        );
                      case "strukturierte_liste":
                        return (
                          <ParagraphStrukturierteListe
                            {...properties}
                            nodeContent={this.props.nodeContent}
                          />
                        );
                      case "kalender_doppel_einzel_highlight":
                        return (
                          <ParagraphKalenderEinzelDoppel
                            {...properties}
                          />
                        );
                      case "kalenderabschnitt":
                        return (
                          <ParagraphKalenderAbschnitt {...properties} />
                        );
                      case "kalendermonatsuebersicht":
                        return <ParagraphKalenderMonat {...properties} />;
                      case "logos_partners":
                        return <ParagraphLogosPartners {...properties} />;
                      case "teaser_overview_multi":
                        return <TeeaserOverviewMulti {...properties} />;
                      case "pinboard":
                        return <ParagraphPinboard {...properties} />;
                      case "scheduled_module":
                        return (
                          <ParagraphScheduledModule {...properties} />
                        );
                      case "bite":
                        return <ParagraphBite {...properties} />;
                      case "artist_overview":
                        return <ParagraphArtistOverview {...properties} />;
                      case "in_page_navigation":
                        return <InPageNavigation modules={this.props.content} />;
                      case "social_media_info":
                        return <ParagraphSocialMediaInfo {...properties} />;
                      case "infoscreen_slider":
                        return <ParagraphInfoscreenSlider {...properties} />;
                      default:
                        return null;
                    }
                  })()
                }
              </div>
            }
          </ErrorBoundary>
        ))}
      </section>
    );
  }
}

ContentBase.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        fieldReusableParagraph: PropTypes.object,
        entityBundle: PropTypes.string.isRequired,
        fieldManuelleSortierung: PropTypes.bool,
      }),
    })
  ),
  className: PropTypes.string,
  nodeContent: PropTypes.object,
};

export default connect(mapStateToProps)(ContentBase);