import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import { InlineNotification } from "carbon-components-react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import queryString from "query-string";

import { authenticationLoginAction } from "./redux-authentication";
import ExecutionEnvironment from "exenv";
import { tokenExpired } from "./util";
import {FormattedMessage} from "react-intl";
import MenuCTA from "../header/menu-cta";

const mapStateToProps = (reduxStore) => ({
  auth: reduxStore.auth,
  menuCta: reduxStore.appStore.menuCta,
  mainSettings: reduxStore.appStore.mainSettings
});

class LoginForm extends Component {
  state = {
    loading: false,
    error: "",
    success: false,
    loginQuery: queryString.parse(this.props.location.search),
  };

  handleSubmit = (values, actions) => {
    this.setState({
      loading: true,
      error: "",
    });

    axios
      .post(
        `/api/v1/login`,
        {
          name: values.email,
          pass: values.password,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
          withCredentials: false,
        }
      )
      .then((response) => {
        console.log(response.data);
        this.setState({ success: true });

        setTimeout(() => {
          this.props.dispatch(authenticationLoginAction(response.data));
        }, 3000);
      })
      .catch((error) => {
        this.setState({
          error: error.response.data.error,
        });
      })
      .then(() => {
        actions.setSubmitting(false);
      });
  };

  render() {
    if (
      this.props.auth.userLoginData.logout_token &&
      ExecutionEnvironment.canUseDOM &&
      this.props.auth.userLoginData.temp_pass
    ) {
      return <Redirect to="/intern/reset-pass/temp" />;
    }

    if (
      this.props.auth.userLoginData.logout_token &&
      !tokenExpired(
        this.props.auth.userLoginData.access_token,
        this.props.auth.userLoginData.jwt.exp
      ) &&
      ExecutionEnvironment.canUseDOM
    ) {
      // Redirect user to given search param. If not given, it defaults to dashboard-url
      const params = new URLSearchParams(location.search);
      const redirectUrl = params.get('redirect');

      return (
        <Redirect
          to={ redirectUrl ||
            (this.state.loginQuery.destination
              ? this.state.loginQuery.destination
              : (this.props.mainSettings.fieldHomepageInternal?.url?.path || "/dashboard-interner-bereich"))
          }
        />
      );
    }

    return (
      <div className="login-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-16 col-md-8 image-wrapper d-none d-md-flex">
              {this.props.menuCta &&
                <MenuCTA
                  content={this.props.menuCta}
                />
              }
            </div>
            <div className="col-16 col-md-6 offset-md-1 form-wrapper">
              {this.state.success && (
                <InlineNotification
                  kind="success"
                  title="Anmeldung erfolgreich!"
                  subtitle="Sie werden automatisch weitergeleitet."
                />
              )}
              {!this.state.success && this.state.error !== "" && (
                <InlineNotification
                  kind="error"
                  title="Authentication Error"
                  subtitle={this.state.error}
                />
              )}
              <h1>
                {this.props.menuCta?.fieldLoginTitle &&
                  <>
                    {this.props.menuCta.fieldLoginTitle}
                  </>
                }
              </h1>
              {this.props.menuCta?.fieldTextLogin &&
                <div
                  className={"text login"}
                  dangerouslySetInnerHTML={{ __html: this.props.menuCta.fieldTextLogin.processed }}
                />
              }
              <Formik
                onSubmit={(values, actions) => {
                  this.handleSubmit(values, actions);
                }}
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={yup.object().shape({
                  email: yup
                    .string()
                    //.email()
                    .required("Dies ist ein Pflichtfeld."),
                  password: yup
                    .string()
                    .required("Dies ist ein Pflichtfeld"),
                })}
                className="form-container"
              >
                <Form>
                  <div className="form-group">
                    <Field
                      type="text"
                      name="email"
                      className="form-control"
                    />
                    <label htmlFor="email">
                      E-Mail
                    </label>
                    <ErrorMessage name="email" />
                  </div>
                  <div className="form-group">
                    <Field
                      type="password"
                      name="password"
                      className="form-control"
                    />
                    <label htmlFor="password">
                      <FormattedMessage id="login.password" />
                    </label>
                    <ErrorMessage name="password" />
                  </div>
                  <div className="form-group submit-wrapper">
                    <button type="submit" className="btn btn-primary next">
                      <FormattedMessage id="login.loginButton" />
                    </button>
                    {/*<Link to="/intern/forgot-password">
                    <FormattedMessage id="login.forgotPassword" />
                  </Link>*/}
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginForm.propTypes = {
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(LoginForm);
