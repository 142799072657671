import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, FieldArray, useFormikContext } from "formik";
import classNames from "classnames";

import { states } from "../states";
import TextField from "./text-field";
import SelectField from "./select-field";

const CompositeAddressField = ({
  item,
  generatedInitialValues,
  compositeIndex,
  compositeParent,
}) => {
  const { values } = useFormikContext();

  const { invisible, visible, optional, required } = states(
    item.states,
    values
  );

  const initialValue = compositeParent
    ? values[compositeParent][compositeIndex][item.id]
    : values[item.id];

  const compositeSettings = JSON.parse(item.compositeSettings),
    showCompositeField = (field) => {
      return (
        (Object.prototype.isPrototypeOf.call(
          compositeSettings,
          `${field}__access`
        ) &&
          compositeSettings[`${field}__access`]) ||
        !Object.prototype.isPrototypeOf.call(
          compositeSettings,
          `${field}__access`
        )
      );
    };

  const proccessedElements = item.elements.map((compositeElement) => {
    if (
      Object.prototype.isPrototypeOf.call(
        compositeSettings,
        `${compositeElement.id}__title`
      )
    ) {
      //element.title = compositeSettings[`${compositeElement.id}__title`];
      return {
        ...compositeElement,
        title: compositeSettings[`${compositeElement.id}__title`],
      };
    }

    return compositeElement;
  });

  return (
    <div
      className={classNames({
        "form-group webform-composite-address": true,
        hidden: invisible || !visible,
      })}
      style={item.flex ? { flex: item.flex } : {}}
    >
      <label htmlFor={item.id}>
        <h3>
          {item.title}
          {(!!item.required || required) && !optional && visible && (
            <span className="required">*</span>
          )}
        </h3>
      </label>

      <FieldArray
        name={item.id}
        render={(arrayHelpers) => (
          <>
            {[...Array(initialValue.length)].map((i, index) => (
              <React.Fragment key={index}>
                {showCompositeField("first_name") ||
                showCompositeField("last_name") ? (
                  <div className="form-row name">
                    {showCompositeField("first_name") && (
                      <div className="col-16 col-md-8">
                        <TextField
                          item={proccessedElements[0]}
                          compositeParent={
                            compositeParent
                              ? `${compositeParent}.${compositeIndex}.${item.id}`
                              : item.id
                          }
                          compositeIndex={undefined}
                        />
                      </div>
                    )}
                    {showCompositeField("last_name") && (
                      <div className="col-16 col-md-8">
                        <TextField
                          item={proccessedElements[1]}
                          compositeParent={
                            compositeParent
                              ? `${compositeParent}.${compositeIndex}.${item.id}`
                              : item.id
                          }
                          compositeIndex={undefined}
                        />
                      </div>
                    )}
                  </div>
                ) : null}
                <div className="form-row">
                  {showCompositeField("organization") && (
                    <div className="col-16">
                      <TextField
                        item={proccessedElements[2]}
                        compositeParent={
                          compositeParent
                            ? `${compositeParent}.${compositeIndex}.${item.id}`
                            : item.id
                        }
                        compositeIndex={undefined}
                      />
                    </div>
                  )}
                  {showCompositeField("address_line_1") ||
                  showCompositeField("address_line_2") ? (
                    <div className="col-16 street-address">
                      {showCompositeField("address_line_1") && (
                        <TextField
                          item={proccessedElements[3]}
                          compositeParent={
                            compositeParent
                              ? `${compositeParent}.${compositeIndex}.${item.id}`
                              : item.id
                          }
                          compositeIndex={undefined}
                        />
                      )}
                      {showCompositeField("address_line_2") && (
                        <TextField
                          item={proccessedElements[4]}
                          compositeParent={
                            compositeParent
                              ? `${compositeParent}.${compositeIndex}.${item.id}`
                              : item.id
                          }
                          compositeIndex={undefined}
                        />
                      )}
                    </div>
                  ) : null}
                </div>
                {showCompositeField("postal_code") ||
                showCompositeField("locality") ? (
                  <div className="form-row">
                    {showCompositeField("postal_code") && (
                      <div className="col-16 col-md-4">
                        <TextField
                          item={proccessedElements[5]}
                          compositeParent={
                            compositeParent
                              ? `${compositeParent}.${compositeIndex}.${item.id}`
                              : item.id
                          }
                          compositeIndex={undefined}
                        />
                      </div>
                    )}
                    {showCompositeField("locality") && (
                      <div className="col-16 col-md-12">
                        <TextField
                          item={proccessedElements[6]}
                          compositeParent={
                            compositeParent
                              ? `${compositeParent}.${compositeIndex}.${item.id}`
                              : item.id
                          }
                          compositeIndex={undefined}
                        />
                      </div>
                    )}
                  </div>
                ) : null}
                {showCompositeField("country") && (
                  <div className="form-row">
                    <div className="col-16">
                      <SelectField
                        item={proccessedElements[7]}
                        compositeParent={
                          compositeParent
                            ? `${compositeParent}.${compositeIndex}.${item.id}`
                            : item.id
                        }
                        compositeIndex={undefined}
                      />
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}

            {!!item.multiple &&
            (item.multiple.limit === 0 ||
              item.multiple.limit > initialValue.length) ? (
              <div>
                <button
                  type="button"
                  onClick={() =>
                    arrayHelpers.push(generatedInitialValues[item.id][0])
                  }
                >
                  Element hinzufügen
                </button>
              </div>
            ) : null}
          </>
        )}
      />
      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />
      {!!item.description && (
        <small
          className="form-description text-muted form-text"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      )}
    </div>
  );
};

CompositeAddressField.propTypes = {
  item: PropTypes.shape({
    states: PropTypes.object,
    compositeSettings: PropTypes.string,
    id: PropTypes.string,
    elements: PropTypes.array,
    title: PropTypes.string,
    flex: PropTypes.number,
    required: PropTypes.object,
    description: PropTypes.string,
    multiple: PropTypes.shape({
      limit: PropTypes.number,
      message: PropTypes.string,
    }),
  }),
  generatedInitialValues: PropTypes.object,
  compositeIndex: PropTypes.number,
  compositeParent: PropTypes.string,
};

export default CompositeAddressField;
