import React, { Component } from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";

// Router
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { isMobileAction, mainSettingsAction } from "./app-actions";

// i18n
import { IntlProvider } from "react-intl";
import messages_de from "./i18n/translations/de.json";
import messages_en from "./i18n/translations/en.json";

// Page Components
import footerConfigQuery from "footer-config-query.graphql";
import PageFooter from "./footer/page-footer";
import menuQuery from "./general-components/menu-query.graphql";
import SimpleHeader from "./header/simple-header";
import ErrorBoundary from "./error-boundary";
import { authenticationLoginAction } from "./intern/redux-authentication";
import { tokenExpired } from "./intern/util";
import configQuery from "./config-query.graphql";

import getConfigID from "./lib/get-config-id";
import {enableBodyScroll} from "body-scroll-lock-upgrade";
import {scrollOptions} from "./lib/scroll-options";
import AppSwitch from "./app-switch";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({ reduxStore });

const messages = {
  de: messages_de,
  en: messages_en,
};

/**
 * @todo 404.
 * @todo Fetch Page title and push to store. Use for <title> tag.
 */
class App extends Component {
  /**
   * Generate Base Class names for main wrapper.
   * @see https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
   *
   * @param {string} pathname - Current Path
   * @returns {string} - Base Classes
   */
  generateMainClasses = memoize((pathname) => {
    const pathNameSplitted = pathname.split("/");

    // Remove first (empty) item
    pathNameSplitted.shift();

    return `${pathNameSplitted[0]} ${pathNameSplitted
      .splice(1, pathNameSplitted.length)
      .join("-")}`;
  });

  menu = React.createRef();

  updateDimensions = () => {
    if (window.innerWidth >= 768) {
      this.props.dispatch(isMobileAction(false));
    } else {
      this.props.dispatch(isMobileAction(true));
    }
  };

  componentDidMount() {
    document.body.className += `${this.generateMainClasses(
      this.props.location.pathname
    )}`;

    // Clear login status if jwt is expired.
    if (
      this.props.reduxStore.auth.userLoginData.access_token &&
      this.props.reduxStore.auth.userLoginData.jwt.exp <
        Math.round(new Date().getTime() / 1000)
    ) {
      this.props.dispatch(authenticationLoginAction({}));
    }

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentDidUpdate(prevProps) {
    // Clear login status if jwt is expired.
    if (
      this.props.reduxStore.auth.userLoginData.access_token &&
      tokenExpired(
        this.props.reduxStore.auth.userLoginData.access_token,
        this.props.reduxStore.auth.userLoginData.jwt.exp
      )
    ) {
      this.props.dispatch(authenticationLoginAction({}));
    }

    if (
      this.props.mainSettingsConfig.configPagesQuery?.entities[0] &&
      !this.props.reduxStore.appStore.mainSettings
    ) {
      this.props.dispatch(
        mainSettingsAction(
          this.props.mainSettingsConfig.configPagesQuery?.entities[0]
        )
      );
    }

    // Scroll to top on route change
    if (
      this.props.location !== prevProps.location &&
      !this.props.location.hash
    ) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
      document.body.className = `${this.generateMainClasses(
        this.props.location.pathname
      )}`;
      enableBodyScroll(document.getElementById('site-header'), scrollOptions);
    }
  }

  render() {
    // This variable checks if the current page is a infoscreen. If its true, we do not need any navigation.
    const normalPage = !location.pathname.includes("infoscreen");

    return (
      <IntlProvider
        locale={this.props.reduxStore.i18n.currentLanguage}
        defaultLocale="de"
        messages={messages[this.props.reduxStore.i18n.currentLanguage]}
      >
        <div className="main-content">
          {normalPage &&
            <header
              id="site-header"
              data-is-frontpage={this.props.location.pathname === "/"}
              ref={this.menu}
            >
              <ErrorBoundary>
                <SimpleHeader
                  location={this.props.location}
                  menuRef={this.menu}
                />
              </ErrorBoundary>
            </header>
          }

          <main className="main-page-content">
            <ErrorBoundary>
              <AppSwitch location={this.props.location} />
            </ErrorBoundary>
          </main>

          {normalPage &&
            <footer id="pageFooter">
              <ErrorBoundary>
                <PageFooter
                  footerConfig={this.props.footerConfig.configPagesById}
                  menu={this.props.menuQuery.menuByName}
                  legalMenu={this.props.legalMenuQuery.menuByName}
                />
              </ErrorBoundary>
            </footer>
          }
        </div>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  footerConfig: PropTypes.object.isRequired,
  menuQuery: PropTypes.object.isRequired,
  reduxStore: PropTypes.object,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(
  compose(
    graphql(footerConfigQuery, {
      options: (props) => ({
        variables: {
          id: getConfigID(props.reduxStore?.appStore?.microSite),
        },
      }),
      name: "footerConfig",
    }),
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: "footer",
          language: props.reduxStore.i18n.currentLanguage
            ? props.reduxStore.i18n.currentLanguage.toUpperCase()
            : "DE",
        },
      }),
      name: "menuQuery",
    }),
    graphql(configQuery, {
      options: {
        variables: { name: "main_settings" },
      },
      name: "mainSettingsConfig",
    }),
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: "legal-menu",
          language: props.reduxStore.i18n.currentLanguage
            ? props.reduxStore.i18n.currentLanguage.toUpperCase()
            : "DE",
        },
      }),
      name: "legalMenuQuery",
    })
  )(withRouter(App))
);
