import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { graphql } from "@apollo/client/react/hoc";
import moment from "moment";
import { connect } from "react-redux";

import Link  from "./../../../link/link";


import teaserNodeQueryEventDateFiltered from "../../../teaser-base/queries/teaser-node-query-event-date-filtered.graphql";
import TeaserEvent, {
  teaserEventPropTypes,
} from "../../../teaser-base/event/teaser-event";
import ErrorBoundary from "../../../../error-boundary";
import TeaserEventCalendarDetail from "../../../teaser-base/event/teaser-event-calendar-detail"
import Select from 'react-select';
import { FormattedMessage, injectIntl } from "react-intl";
import LoadingIndicator from "../../../loading-indicator";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
  microSite: reduxStore.appStore.microSite
});

class ParagraphKalenderAbschnitt extends Component {
  constructor(props) {
    super(props);

    // Overwrite Nodes from props when needed, if this module is only used as display mode for event teaser list (automatically or manually filled).
    let nodes = this.props.overWriteNodes || this.props.nodes,
      filteredContent = this.props.overWriteNodes ? this.props.overWriteNodes?.nodeQuery?.entities : null;

    if (this.props.manualNodes) {
      // Get only Future dates
      const manualNodes = this.props.futureEventsOnly ? this.props.manualNodes.filter((event) => moment.unix(event.entity.fieldDate?.value).isAfter()) : this.props.manualNodes;

      nodes = manualNodes;
      filteredContent = manualNodes;
    }

    this.state = {
      activeItem: 0,
      availableMonths: [],
      selectedMonth: false,
      availableCategories: [],
      selectedCategory: false,
      filteredContent,
      nodes
    }
  }

  calendar = React.createRef();

  highlightEvent = (index) => {
    this.setState({
      activeItem: index
    })
  }

  filterContent = (type) => {
    if (this.props.nodes.nodeQuery && this.props.nodes.nodeQuery.entities && !this.props.manualNodes) {
      let filteredContent = this.props.nodes.nodeQuery.entities;

      // Filter for Category
      if (this.state.selectedCategory) {
        if (this.state.selectedCategory.value !== "reset-categories") {
          filteredContent = filteredContent.filter((event) => {
            let returnValue = false;

            // Loop Through all Tags of event to check if they are matching
            event.fieldEventCategory.map(tag => {
              if (tag.entity.entityId === this.state.selectedCategory.value) {
                returnValue = true;
              }
            });
            return returnValue;
          });
        }
      }
      this.setState({ filteredContent });
    } else if (this.props.manualNodes) {
      this.setState({ filteredContent: this.props.manualNodes });
    }
  }

  scrollToMonth = (selectedOption) => {
    let scrollAnchor = document.getElementById(selectedOption.value);

    if (scrollAnchor) {
      window.scrollTo({
        top: scrollAnchor.getBoundingClientRect().top - 200,
        behavior: "smooth",
      });
    }
  }

  handleChange = (selectedOption) => {
    if (selectedOption.type === "month") {
      this.setState({ selectedMonth: selectedOption },() => { this.scrollToMonth(selectedOption)});
    } else {
      this.setState({ selectedCategory: selectedOption },() => { this.filterContent("category")});
    }
  };

  componentDidUpdate(prevProps) {
    if (!this.props.miniCalendarView) {
      if (this.props.nodes.nodeQuery !== prevProps.nodes.nodeQuery) {
        this.filterContent("initial");
        this.initializeFilters();
        if (!this.state.filteredContent) {
          this.filterContent("initial");
          this.initializeFilters();
        }
      }
    }
  }

  initializeFilters = () => {
    let availableCategories = [
        {
          value: "reset-categories",
          label: "Alle Kategorien",
          type: "categories"

        }
      ],
      availableMonths = [
        {
          value: "reset-months",
          label: "Jederzeit",
          type: "month"

        }
    ];

    // Always filter from all possible options, scince the filter is only one dimensional

    if (this.props.nodes.nodeQuery && this.props.nodes.nodeQuery.entities) {
      this.props.nodes.nodeQuery.entities.map(item => {
          item.fieldEventCategory.map(item => {
            // Check for duplicates, only add if not double.
            if (!availableCategories.some((id) => id.value === item.entity.entityId)) {
              availableCategories = [...availableCategories, { value: item.entity.entityId, label: item.entity.name, type: "category" }];
            }
          }
        );
          // Get Months of Events, check for duplicates
        if (!availableMonths.some((month) => month.value === moment.unix(item?.fieldDate?.value).format("MM-YYYY"))) {
          availableMonths = [...availableMonths, { value: moment.unix(item?.fieldDate?.value).format("MM-YYYY"), label: moment.unix(item.fieldDate.value).format("MMMM 'YY"), type: "month" }];
        }
      });
    }

    this.setState({
      availableCategories,
      availableMonths
    });
  }

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-kalender-abschnitt full-bleed": true,
      "heading-only": this.props.content?.fieldNurUeberschriftZeigen,
    });

    if ((!this.props.nodes.loading && !!this.props.nodes?.nodeQuery) || this.props.manualNodes) {
      return (
        <section className={sectionClassNames} ref={this.calendar}>
          <div className="container">
            <div className="row">
              <div className="col-16 col-lg-10 events" >
                {this.props.title && this.props.miniCalendarView &&
                  <h3 className={"calendar-title"}>
                    {this.props.title}
                  </h3>
                }
                {(!this.props.manualNodes && !this.props.miniCalendarView) &&
                  <div className="filter-wrapper">
                    <Select
                      value={this.state.selectedOption}
                      onChange={this.handleChange}
                      options={this.state.availableMonths}
                      placeholder={"Monat"}
                      isSearchable={false}
                    />
                    <Select
                      value={this.state.selectedOption}
                      onChange={this.handleChange}
                      options={this.state.availableCategories}
                      placeholder={"Kategorien"}
                      isSearchable={false}
                    />
                  </div>
                }
                {this.state.filteredContent &&
                  this.state.filteredContent.length > 0 && (
                    <>
                      {this.state.filteredContent.map((item, index) => {
                        const currentDate = item?.entity || item,
                          currentDateStart = moment.unix(currentDate?.fieldDate?.value),
                          currentDateEnd = moment.unix(currentDate?.fieldDate?.endValue),
                          dateBeforeStart = moment.unix(this.state.filteredContent[index - 1]?.fieldDate?.value),
                          dateBeforeEnd = moment.unix(this.state.filteredContent[index - 1]?.fieldDate?.endValue);

                        return (
                          <ErrorBoundary key={index}>
                            {this.state.filteredContent[index - 1] && dateBeforeStart.format("MM-YYYY") !== currentDateStart.format("MM-YYYY") &&
                              <div className="month-break">
                                {currentDateStart.format("MMMM")}
                              </div>
                            }
                            <div
                              className={`event-wrapper ${this.state.activeItem === index ? 'active' : 'inactive'} ${this.state.filteredContent[index - 1] && dateBeforeStart.format("DD-MM-YYYY") === currentDateStart.format("DD-MM-YYYY") && (currentDateStart.format("DD-MM-YYYY") === currentDateEnd.format("DD-MM-YYYY")) ? 'same-day' : 'new-day'}`}
                              onMouseEnter={() => this.highlightEvent(index)}
                              //Setting Month breaks for scroll spy on every first day in month
                              id={index === 0 || (this.state.filteredContent[index - 1] && dateBeforeStart.format("MM-YYYY") !== currentDateStart.format("MM-YYYY")) ?  currentDateStart.format("MM-YYYY") : false }
                              data-highlighted={item.fieldHighlighted}
                            >
                              <TeaserEvent
                                item={item?.entity || item}
                                hideImage={false}
                              />
                            </div>
                          </ErrorBoundary>
                        );
                      })}
                    </>
                  )}
                {this.props.overviewLink &&
                  <div className="overview-link">
                    <Link link={this.props.overviewLink}/>
                  </div>
                }
              </div>
              <div className="col-16 col-lg-6 event-detail">
                {this.state.filteredContent &&
                  <TeaserEventCalendarDetail item={(this.state.filteredContent[this.state.activeItem]?.entity || this.state.filteredContent[this.state.activeItem]) || this.state.filteredContent[this.state.activeItem]}/>
                }
              </div>
            </div>
          </div>
        </section>
      );
    } else {
      return (
        <LoadingIndicator fullPage={true}/>
      );
    }
  }
}

ParagraphKalenderAbschnitt.propTypes = {
  content: PropTypes.shape({
    __typename: PropTypes.string,
    entityId: PropTypes.string,
    entityBundle: PropTypes.string,
    fieldHeading: PropTypes.string,
    fieldNurUeberschriftZeigen: PropTypes.bool,
    fieldZeitraumAuswaehlenNach: PropTypes.oneOf(["relative", "static"]),
    fieldFestesDatum: PropTypes.shape({
      __typename: PropTypes.string,
      startDate: PropTypes.string,
      value: PropTypes.string,
      endDate: PropTypes.string,
      endValue: PropTypes.string,
    }),
    fieldRelativesDatum: PropTypes.oneOf([
      "today",
      "tomorrow",
      "this week",
      "next week",
      "this month",
      "next month",
    ]),
  }),
  nodes: PropTypes.shape({
    nodeQuery: PropTypes.shape({
      entities: PropTypes.arrayOf(teaserEventPropTypes),
    }),
  }),
};

export default connect(mapStateToProps)(
  graphql(teaserNodeQueryEventDateFiltered, {
    name: "nodes",
    skip: (props) => props.content?.fieldNurUeberschriftZeigen,
    options: (props) => {
      return {
        variables: {
          type: "veranstaltung",
          date: moment().unix().toString(),
          language: props.currentLanguage.toUpperCase(),
          microSiteFilterEnabled: true,
          microSiteFilter: props.microSite ? props.microSite : "hmdk",
          hideInCalendarFilterEnabled: true
        },
      };
    },
  })(injectIntl(ParagraphKalenderAbschnitt))
);
