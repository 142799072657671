import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";
import ErrorBoundary from "../../../../error-boundary";

import { TeaserSlide } from "./teaser-slide";
import {connect} from "react-redux";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
  microSite: reduxStore.appStore.microSite,
  isMobile: reduxStore.appStore.isMobile
});

class ParagraphTeaserSlider extends Component {
  state = {
    fontFitted: false,
    playMarquee: false,
    sliderSettings: {
      arrows: true,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchMove: true,
    },
  };

  onTextFitted = () => {
    if (!this.state.fontFitted) {
      this.setState({
        fontFitted: true
      });
    }
  }

  toggleMarquee = (status) => {
    this.setState({
      playMarquee: status
    });
  }

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-teaser-slider full-bleed": true,
    });

    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldTeaserSlides.length > 1 ? (
          <ErrorBoundary>
            <Slider {...this.state.sliderSettings}>
              {this.props.content.fieldTeaserSlides.map((item, index) => (
                <TeaserSlide
                  item={item}
                  index={index}
                  fontFitted={this.state.fontFitted}
                  onTextFitted={() => this.onTextFitted()}
                  toggleMarquee={() => this.toggleMarquee()}
                />
              ))}
            </Slider>
          </ErrorBoundary>
        ) : (
          <ErrorBoundary>
            <TeaserSlide
              item={this.props.content.fieldTeaserSlides[0]}
              index={0}
              fontFitted={this.state.fontFitted}
              onTextFitted={() => this.onTextFitted()}
              toggleMarquee={() => this.toggleMarquee()}
            />
          </ErrorBoundary>
        )}

      </section>
    );
  }
}

ParagraphTeaserSlider.propTypes = {
  content: PropTypes.shape({
    fieldTeaserSlides: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldTeaserueberschrift: PropTypes.string.isRequired,
          fieldImageOrVideo: PropTypes.shape({
            entity: PropTypes.shape({
              fieldMediaImage: PropTypes.shape({
                alt: PropTypes.string,
                title: PropTypes.string,
                style: PropTypes.shape({
                  url: PropTypes.string.isRequired,
                }),
              }),
            }),
          }),
          fieldText: PropTypes.shape({
            processed: PropTypes.string,
          }),
          fieldInhaltLink: PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.shape({
              routed: PropTypes.bool,
              path: PropTypes.string,
              entity: PropTypes.shape({
                title: PropTypes.string
              })
            })
          }),
        }),
      })
    ),
  }),
};

export default connect(mapStateToProps)(ParagraphTeaserSlider);
