import React, { useEffect, useRef, useState } from "react";
import { encodeUrl } from "../../lib/encode-url";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { Link } from "react-scroll";
import useOutsideClickHandler from "../../hooks/use-outside-click-handler";
import PropTypes from "prop-types";
import Arrow from "../icons/arrow";
import {useSelector} from "react-redux";

const InPageNavigation = ({ modules }) => {
  const navRef = useRef();
  const [open, setOpen] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [inPageNaviPosition, setInPageNaviPosition] = useState(0);
  const [y, setY] = useState(0);
  const isMobile = useSelector((state) => state.appStore.isMobile);

  const links = modules.filter((item) => item.entity.fieldSetAnchorLink && item.entity.status).map((item) => item.entity.fieldHeading || item.entity.fieldTitle);

  if (!links.length) return null;

  const navHeight = navRef.current?.scrollHeight;

  const formattedLinks = links.map((link) => ({
    label: link,
    link: encodeUrl(link),
  }));

  const toggleOpenHandler = () => {
    setOpen((prev) => {
      if (prev) {
        // if it was opened, close it

        navRef.current.style.maxHeight = "0px";
      } else {
        // if it was closed, open it, but with a delay to ensure the label wrapper is already grown
        setTimeout(() => {
          navRef.current.style.maxHeight = navHeight + "px";
        }, 500);
      }

      return !prev;
    });
  };

  /*
  *  Animation Optimization on Opening & Closing
  *  Basically, we could use the class change provided by changing "open" State. But however, we need a transitioning delay to ensure a smooth animation.
  *  It is needed because of the way the animation works:
  *  When opening: Expand the label-area, after that open the selectable nav.
  *  When closing: Close the selectable nav, shrink the label-area
 */
  useEffect(() => {
    if (open) {
      sectionRef.current.querySelector(".click-wrapper")?.classList.add("expanded");
    } else {
      setTimeout(() => {
        setTimeout(() => {
          sectionRef.current.querySelector(".click-wrapper")?.classList.remove("expanded");
        }, 500);
      }, 0);
    }
  }, [open]);

  const sectionRef = useOutsideClickHandler(toggleOpenHandler, open);

  const scrollHandler = () => {
    const scrolledY = window.scrollY;

    setY((prevY) => {
      if (scrolledY < inPageNaviPosition) {
        setHidden(false);
      } else {
        if (prevY < scrolledY) {
          setHidden(true);
          navRef.current.style.maxHeight = "0px";
          setOpen(false);
        }
        if (prevY > scrolledY) setHidden(false);
      }
      return scrolledY;
    });
  };

  useEffect(() => {
    // Get Position of Inpage Navi and add 300px offset to it
    setInPageNaviPosition(sectionRef.current.getBoundingClientRect().top + 600);

    if (isMobile) {
      // Get Max-Width of inpage-Nav Container by checking maximum possible width
      sectionRef.current.style.setProperty("--nav-max-width", `${sectionRef.current?.querySelector(".click-wrapper .label")?.getBoundingClientRect().width}px`);
    } else {
      // Get Max-Width of inpage-Nav Container by checking max-length of contents
      sectionRef.current.style.setProperty("--nav-max-width", `${sectionRef.current?.querySelector("nav")?.getBoundingClientRect().width}px`);
    }
  }, []);

  useEffect(() => {
    // Set Scroll Listener after InPageNaviPosition is set, to ensure the element is loaded and the position is known.
    document && document.addEventListener("scroll", scrollHandler);

    // cleanup
    return () => {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, [inPageNaviPosition]);

  return (
    <section
      className={classNames({
        "in-page-navigation": true,
        hidden: hidden,
        open: open
      })}
      ref={sectionRef}
    >
      <div className="container">
        <div className="row">
          <div className="col-16">
            <div
              className={"click-wrapper"}
              role="button"
              onClick={toggleOpenHandler}
            >
              <div
                className={classNames({
                  label: true
                })}
              >
                <FormattedMessage id="inpage.on_this_page" />
              </div>
              <Arrow
                orientation={"left"}
                width={"1"}
              />
            </div>
            <nav ref={navRef} style={{ maxHeight: "0px" }}>
              <ul>
                {formattedLinks.map((link, i) => (
                  <li key={i}>
                    <Link
                      to={link.link}
                      onClick={toggleOpenHandler}
                      smooth={true}
                      offset={-300}
                      duration={500}
                    >
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};

InPageNavigation.propTypes = {
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        fieldHeading: PropTypes.string,
      }),
    })
  ),
};

export default InPageNavigation;
