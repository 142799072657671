import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import LoadingIndicator from "../general-components/loading-indicator";
import infoscreenQuery from "./inforscreen.graphql";
import Error404 from "../error-404";
import ContentBase from "../general-components/content-base/content-base";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  pagerFullPageContextId: reduxStore.appStore.pagerFullPageContextId,
  pagerConfig: reduxStore.appStore.pagerFullPages,
  microSite: reduxStore.appStore.microSite,
  microSiteFrontPage: reduxStore.appStore.microSiteFrontPage,
  auth: reduxStore.auth,
});

class Inforscreen extends Component {

  render() {
    if (!this.props.data.loading && this.props.data.route === null) {
      return <Error404/>;
    }

    if (this.props.data.route) {
      const published = this.props.data.route.entity.status
        ? "node-published"
        : "node-unpublished";

      return (
        <article className={`infoscreen ${published}`}>
          <Helmet>
            <title>{`HMDK Stuttgart | ${this.props.data.route.entity.entityLabel}`}</title>
          </Helmet>

          {this.props.data.route.entity.fieldInfoscreenContent && (
            <ContentBase
              content={[this.props.data.route.entity.fieldInfoscreenContent]}
            />
          )}
        </article>
      );
    }

    return <LoadingIndicator fullPage={true} />
  }
}

Inforscreen.propTypes = {
  auth: PropTypes.object,
  data: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.array,
  pagerFullPageContextId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
};

export default connect(mapStateToProps)(
  graphql(infoscreenQuery, {
    options: (props) => ({
      variables: {
        path: props.location.pathname,
      },
    }),
  })(withRouter(Inforscreen))
);
