import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import {Link, withRouter} from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { FormattedMessage, injectIntl } from "react-intl";
import {self} from '../../../config';
import { MobileView } from 'react-device-detect';

import EditButton from "../../../backend/edit-button";
import { AddToCalendar } from "../../../event/add-to-calendar";
import { EventType } from "../../../event/event-type";
import { ClassCooperation } from "../../../event/class-cooperation";
import { Streamlink } from "../../../event/streamlink";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

/**
 * @todo use "FieldTime"
 */
class TeaserEvent extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    return (
      <article
        className={`node node-${this.props.item.entityId} node-teaser node-teaser-default teaser-veranstaltung container`}
      >
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.entityId}
          destinationRoute={this.props.location.pathname}
        />
        <div className="row">
          {this.props.item.fieldBild && !this.props.hideImage && (
            <Link
              className="col-16 image-col"
              to={this.props.item.path.alias}
            >
              <MobileView className={"teaser-image"}>
                <Image
                  data={this.props.item.fieldBild.entity.fieldMediaImage}
                  nodeTitle={this.props.item.title}
                />
              </MobileView>
            </Link>
          )}
          <Link
            className="col-16 date-col"
            to={this.props.item.path.alias}
          >
            <div className="date">
              <span>
                {moment
                  .unix(this.props.item.fieldDate?.value)
                  .local()
                  .format("dd")}
              </span>
              <span>
                {moment
                  .unix(this.props.item.fieldDate?.value)
                  .local()
                  .format("DD. MMM")
                }

                {this.props.item.fieldDate?.endValue && (moment.unix(this.props.item.fieldDate?.value).local().format("DD.MM.YYYY") !== moment.unix(this.props.item.fieldDate?.endValue).local().format("DD.MM.YYYY")) &&
                  <>
                    {"–"}
                    {moment
                      .unix(this.props.item.fieldDate?.endValue)
                      .local()
                      .format("DD. MMM")
                    }
                  </>
                }
              </span>
            </div>
            {(this.props.item.fieldPremiere || this.props.item.fieldDerniere) &&
              <div className="premiere-derniere">
                {this.props.item.fieldPremiere &&
                  <FormattedMessage id="event.premiere" />
                }
                {this.props.item.fieldDerniere &&
                  <FormattedMessage id="event.derniere" />
                }
              </div>
            }
          </Link>
          <Link
            className="col-16 col-big"
            to={this.props.item.path.alias}
          >
            {(moment.unix(this.props.item.fieldDate?.value).local().format("HH:mm") !== "00:00" || this.props.item.fieldOrt) &&
              <div className="meta-wrapper">
                {moment
                  .unix(this.props.item.fieldDate?.value)
                  .local()
                  .format("HH:mm") !== "00:00" && (
                  <span>
                  {moment
                    .unix(this.props.item.fieldDate?.value)
                    .local()
                    .format("HH:mm")
                  }
                    {this.props.item.fieldDate?.endValue && (this.props.item.fieldDate?.value !== this.props.item.fieldDate?.endValue) &&
                      <>
                        {"–"}
                        {moment
                          .unix(this.props.item.fieldDate?.endValue)
                          .local()
                          .format("HH:mm")
                        }
                      </>
                    }
                    {" "}
                    Uhr
                </span>
                )}
                {this.props.item.fieldOrt &&
                  <div className="location">{this.props.item.fieldOrt}</div>
                }
              </div>
            }
            <h3>
              {this.props.item.fieldOmitted &&
                <FormattedMessage id={"event.omitted"}/>
              }
              {this.props.item.title}
            </h3>
            {this.props.item.fieldTitle2 &&
              <div
                className="subtitle title-2"
                dangerouslySetInnerHTML={{
                  __html: this.props.item.fieldTitle2
                }}
              />
            }
            {this.props.item.fieldSubtitle &&
              <div
                className="subtitle"
                dangerouslySetInnerHTML={{
                  __html: this.props.item.fieldSubtitle
                }}
              />
            }
            {this.props.item.fieldTitleExtension &&
              <div
                className="subtitle title-extension"
                dangerouslySetInnerHTML={{
                  __html: this.props.item.fieldTitleExtension
                }}
              />
            }

            <ClassCooperation content={this.props.item}/>

          </Link>
          <div className="col-16 cta-col">
            <AddToCalendar content={this.props.item} />
            <EventType content={this.props.item}/>
            <Streamlink content={this.props.item}/>
          </div>
        </div>
      </article>
    );
  }
}

export const teaserEventPropTypes = PropTypes.shape({
  entityId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  entityUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldOrt: PropTypes.string,
  fieldDauerAusblenden: PropTypes.bool,
  fieldText: PropTypes.shape({
    processed: PropTypes.string.isRequired,
  }),
  fieldBild: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
  fieldDate: PropTypes.shape({
    value: PropTypes.string,
    endValue: PropTypes.string,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldTicketlink: PropTypes.shape({
    url: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
});

TeaserEvent.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserEventPropTypes,
  location: PropTypes.object.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(injectIntl(TeaserEvent)));
