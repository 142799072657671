import React, { useState, useEffect } from "react";
import PinboardContentTeaser from "./../../../teaser-base/pinboard-content/teaser-pinboard-content";
import VisibilitySensor from "react-visibility-sensor";
import {useQuery} from "@apollo/client";
import teaserNodeQueryFilterTag from "../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import { connect, useSelector } from "react-redux";
import classNames from "classnames";
import {encodeUrl} from "../../../../lib/encode-url";
import PropTypes from "prop-types";
import CustomHyphenation from "../../../custom-hyphenation";

export const ParagraphPinboard = (props) => {
  const currentLanguage = useSelector(reduxStore => reduxStore.i18n.currentLanguage);

  const { loading, error, data: nodes} = useQuery(teaserNodeQueryFilterTag, {
    skip: props.fieldAutmatischOderManuell === "Manuell",
    variables: {
      limit: props.count ? props.count : 100,
      type: "pinboard_content",
      pinboardType: props.content.fieldPinboardType?.entity?.entityId.toString(),
      filterPinboardTypeEnabled: !!props.content.fieldPinboardType,
      language: currentLanguage.toUpperCase(),
    }
  })

  let content = props.content.fieldManualContent;

  if (props.content.fieldAutmatischOderManuell === "Automatisch (chronologisch)") {
    content = nodes?.nodeQuery?.entities;
  }

  const headingClassNames = classNames({
    "heading": true,
    "small": props.content.fieldHeading?.length > 18,
  });

  return(
    <section className="paragraph paragraph-pinboard">
      <div className="container">
        <div className="row">
          <div className="col-14 offset-1 col-lg-4 heading-wrapper">
            {props.content.fieldHeading &&
              <div className={headingClassNames} id={encodeUrl(props.content.fieldHeading)}>
                <CustomHyphenation length={8}>
                  {props.content.fieldHeading}
                </CustomHyphenation>
              </div>
            }
          </div>
          <div className="col-16 col-lg-9 teaser-wrapper">
            {content && content.map(
              (item, index) => (
                <React.Fragment key={index}>
                  <PinboardContentTeaser content={item}/>
                </React.Fragment>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
ParagraphPinboard.PropTypes ={
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    __typename: PropTypes.string,
    entityId: PropTypes.string,
    entityBundle: PropTypes.string,
    fieldMaunelContent: PropTypes.arrayOf(
      PropTypes.shape({
        __typename: PropTypes.string,
        entity: PropTypes.shape({
          __typename: PropTypes.string,
          entityId: PropTypes.string,
          entityBundle: PropTypes.string,
          entityLabel: PropTypes.string,
          fieldContactType: PropTypes.string,
          fieldMail: PropTypes.string,
          title: PropTypes.string,
          fieldDate: PropTypes.shape({
            value: PropTypes.number,
            __typename: PropTypes.string,
            endTime: PropTypes.string,
            startTime: PropTypes.string,
          }),
          fieldText: PropTypes.shape({
            processed: PropTypes.string,
            __typename: PropTypes.string,
          }),
          fieldFile: PropTypes.shape({
            __typename: PropTypes.string,
            entity: PropTypes.shape({
              __typename: PropTypes.string,
              fieldBeschreibung: PropTypes.shape({
                value: PropTypes.string,
                processed: PropTypes.string,
              }),
              entityLabel: PropTypes.string,
              fieldMediaFile: PropTypes.shape({
                __typename: PropTypes.string,
                entity: PropTypes.shape({
                  __typename: PropTypes.string,
                  url: PropTypes.string,
                }),
              }),
              fieldVorschaubild: PropTypes.shape({
                __typename: PropTypes.string,
                entity: PropTypes.shape({
                  __typename: PropTypes.string,
                  fieldMediaImage: PropTypes.shape({
                    alt: PropTypes.string,
                    title: PropTypes.string,
                    style: PropTypes.shape({
                      url: PropTypes.string,
                    }),
                  }),
                })
              }),
            }),
          }),
        }),
      }),
    ),
    fieldAutomatischOderManeull: PropTypes.string,
    fieldPinboardType: PropTypes.shape({
      __typename: PropTypes.string,
      entity: PropTypes.shape({
        __typename: PropTypes.string,
        entity: PropTypes.shape({
          __typename: PropTypes.string,
          entityId: PropTypes.string,
          name: PropTypes.string,
        }),
      }),
    }),
  }),
}
export default ParagraphPinboard;