import React from "react";
import classNames from "classnames";
import {HighlightHmdk} from "../../../../lib/highlight-hmdk";

const SocialMediaInfo = ({ content }) => {

  const socialMediaInfoClassNames = classNames({
    "social-media-info": true,
    "including-name": content.fieldSocialMediaTitle.includes("HMDK Stuttgart")
  });

  return(
    <div className={socialMediaInfoClassNames}>
      {content.fieldSocialMediaTitle &&
        <HighlightHmdk
          text={content.fieldSocialMediaTitle}
          classNames={"title"}
        />
      }
      {content.fieldSocialMediaLinks &&
        <div className="social-media-links">
          {content.fieldSocialMediaLinks.map((link, index) =>
            <a
              href={link?.url?.path}
              className="btn social-media-link"
              key={index}
            >
              {link.title}
            </a>
          )}
        </div>
      }
    </div>
  );
}

export default SocialMediaInfo;