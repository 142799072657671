import MenuCTA from "./menu-cta";
import Search from "../search/search";
import LanguageSwitch from "../i18n/language-switch";
import Arrow from "../general-components/icons/arrow";
import {FormattedMessage} from "react-intl";
import Menu from "./menu";
import {Link} from "react-router-dom";
import PeopleLink from "./people-link";
import UserStatusBlock from "../intern/user-status-block";
import React from "react";
import MenuButton from "./menu-button";
import {AnimatePresence, motion} from "framer-motion";
import { useSelector } from "react-redux";

const CloseMenu = (props) => {
  return(
    <MenuButton
      menuOpen={props.menuOpen}
      onClick={props.onClick}
      onKeyDown={props.handleKeyDown}
      closeOnly={true}
    />
  );
}

export const FullscreenMenu = (props) => {

  return (
    <div className="fullscreen-menu-wrapper">
      <AnimatePresence
        mode="wait"
      >
        <motion.div
          id="fullscreen-menu"
          key={"fullscreen-menu-wrapper"}
          animate={props.menuOpen ? { right: '0vw' } : { right: '-100vw'  }}
          transition={{ duration: 0.5 }}
        >
          <div className="nav-wrap">
            {props.isMobile &&
              <div className="action-bar">
                <div className="container">
                  <div className="row">
                    <div className="col-8 search-and-language">
                      <Search/>
                      <LanguageSwitch/>
                    </div>
                    <div className="col-8">
                      {props.isMobile &&
                        <CloseMenu
                          menuOpen={props.menuOpen}
                          onClick={props.toggleFullScreenMenu}
                          onKeyDown={props.handleKeyDown}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
            {!props.mainMenuQuery.loading &&
              props.mainMenuQuery.menuByName &&
              <nav className={`mega-menu active-${props.activeLevel} ${props.transitionBlocked ? 'transition-blocked' : 'transition-standard'}`}>
                <div className={`menu-level level-cta`}>
                  {props.menuCtaConfig && props.menuCtaConfig.configPagesQuery?.entities[0] && props.menuCtaConfig.configPagesQuery?.entities[0].fieldMenuCtas &&
                    <MenuCTA
                      content={props.menuCtaConfig.configPagesQuery.entities[0]}
                    />
                  }
                </div>
                {[...Array(3)].map((x, menuLevel) =>
                  <div className={`menu-level body-scroll-lock-ignore level-${menuLevel + 1} level-${menuLevel < props.activeLevel ? 'active' : 'inactive'}`} key={menuLevel}>
                    <div className={`menu-back ${props.showGoBackButton(menuLevel)}`}>
                      <button className={`btn solid`} onClick={() => props.menuGoBack()}>
                        <Arrow orientation={"left"} width={"1"}/>
                        {props.activeLevel === 2 ? (
                          <FormattedMessage id="back" />
                        ) : (
                          <>
                            {props.activeFirstLevel}
                          </>
                        )}
                      </button>
                    </div>
                    {menuLevel === 0 && !props.isMobile &&
                      <CloseMenu
                        menuOpen={props.menuOpen}
                        onClick={props.toggleFullScreenMenu}
                        onKeyDown={props.handleKeyDown}
                      />
                    }
                    <div className="inner-wrapper">
                      <div className="main-menu">
                        <Menu
                          items={props.mainMenuQuery.menuByName.links}
                          level={menuLevel + 1}
                          clickLevelLink={props.clickLevelLink}
                          activeLevel={props.activeLevel}
                          activeLink={props.activeLink}
                          toggleFullScreenMenu={props.toggleFullScreenMenu}
                        />
                      </div>
                      {menuLevel === 0 &&
                        <>
                          {!props.oftenClickedMenuQuery.loading &&
                            props.oftenClickedMenuQuery.menuByName && (
                              <div className="often-clicked">
                                <nav className="often-clicked">
                                  <ul>
                                    {props.oftenClickedMenuQuery.menuByName.links.map(
                                      (item, index) => (
                                        <li
                                          onClick={() => props.toggleFullScreenMenu()}
                                          key={index}
                                        >
                                          <Link to={item.url.path}>{item.label}</Link>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </nav>
                              </div>
                            )}

                          {!props.microSite &&
                            <div className={"bottom-menu"}>
                              <div className="gradient-wrapper">
                                <PeopleLink
                                  config={props.menuCtaConfig.configPagesQuery?.entities[0]}
                                  onClick={props.toggleFullScreenMenu}
                                />
                                {props.auth.userLoginData.logout_token ? (
                                  <UserStatusBlock />
                                ) : (
                                  <button className="login">
                                    <Link
                                      to={"/intern/login"}
                                      className="btn"
                                      onClick={props.toggleFullScreenMenu}
                                    >
                                      Login
                                    </Link>
                                  </button>
                                )}
                              </div>
                            </div>
                          }
                        </>
                      }
                    </div>
                  </div>
                )}
              </nav>
            }
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}