import React from "react";
import PropTypes from "prop-types";
import { useFormikContext, Field, ErrorMessage } from "formik";
import classNames from "classnames";

import { states } from "../states";
import { getValueFromStringObjPath } from "../webform-elements";

const TextField = ({ item, compositeIndex, compositeParent }) => {
  const { values, handleChange, handleBlur } = useFormikContext();

  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    values
  );

  const value = compositeParent
    ? typeof compositeIndex !== "undefined"
      ? getValueFromStringObjPath(values, compositeParent)[compositeIndex][
          item.id
        ]
      : getValueFromStringObjPath(values, compositeParent)[item.id]
    : values[item.id];
  const identifier = compositeParent
    ? typeof compositeIndex !== "undefined"
      ? `${compositeParent}[${compositeIndex}][${item.id}]`
      : `${compositeParent}[${item.id}]`
    : item.id;

  return (
    <div
      className={classNames({
        "form-group": true,
        hidden: invisible || !visible,
      })}
      style={item.flex ? { flex: item.flex } : {}}
    >
      <label htmlFor={identifier}>
        {item.title}
        {(!!item.required || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>

      <Field
        id={identifier}
        name={identifier}
        type="text"
        required={(!!item.required || required) && !optional && visible}
        disabled={!enabled || disabled}
        className="form-control"
        placeholder={item.placeholder != null ? item.placeholder : ""}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        maxLength={item.maxLength ? item.maxLength : null}
        minLength={item.minLength ? item.minLength : null}
      />

      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />
      {!!item.description && (
        <small
          className="form-description text-muted form-text"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      )}
    </div>
  );
};

TextField.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    states: PropTypes.object,
    description: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    flex: PropTypes.number,
    required: PropTypes.object,
  }),
  compositeIndex: PropTypes.number,
  compositeParent: PropTypes.string,
};

export default TextField;
