import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage, useFormikContext } from "formik";

import { states } from "../states";
import classNames from "classnames";

const EmailField = ({ item, compositeIndex, compositeParent }) => {
  const { values, handleChange, handleBlur } = useFormikContext();

  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    values
  );

  return (
    <div
      className={classNames({
        "form-group": true,
        hidden: invisible || !visible,
      })}
      style={item.flex ? { flex: item.flex } : {}}
    >
      <label htmlFor={item.id}>
        {item.title}
        {(!!item.required || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>

      <Field
        id={
          compositeParent
            ? `${compositeParent}[${compositeIndex}][${item.id}]`
            : item.id
        }
        name={
          compositeParent
            ? `${compositeParent}[${compositeIndex}][${item.id}]`
            : item.id
        }
        type="email"
        className="form-control"
        placeholder={item.placeholder != null ? item.placeholder : ""}
        onChange={handleChange}
        onBlur={handleBlur}
        value={
          compositeParent
            ? values[compositeParent][compositeIndex][item.id]
            : values[item.id]
        }
        required={(!!item.required || required) && !optional && visible}
        disabled={!enabled || disabled}
        maxLength={item.maxLength ? item.maxLength : null}
        minLength={item.minLength ? item.minLength : null}
      />

      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />

      {!!item.description && (
        <small
          className="form-description text-muted form-text"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      )}
    </div>
  );
};

EmailField.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    flex: PropTypes.number,
    required: PropTypes.object,
    description: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    states: PropTypes.array,
  }),
  compositeIndex: PropTypes.number,
  compositeParent: PropTypes.string,
};

export default EmailField;
