import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Image from "../../../image/image";
import Slider from "react-slick";

export const InforscreenSlider = ({ content }) => {

  const sliderSettings = {
    arrows: false,
    dots: false,
    autoplaySpeed: content.fieldAutoplaySpeed ? content.fieldAutoplaySpeed * 1000 : 10000,
    autoplay: true,
    slidesToShow: 1,
    lazyLoad: false,
    variableWidth: false,
    slidesToScroll: 1,
    loop: true
  }

  return(
    <section className="infoscreen-slider">
      <Slider {...sliderSettings}>
        {content?.fieldInfoscreenContent?.map((item, index) =>
          <div
            className="infoscreen-slide use-color-scheme"
            key={index}
            data-color-scheme={
              item.entity.fieldColorScheme
                ? item.entity.fieldColorScheme.entity.fieldColorVariable
                : "default"
            }
            data-white-text={item.entity.fieldWhiteText}
            data-show-original-image={item.entity.fieldShowOriginalImage}
          >
            <div className="inner-wrapper">
              {item.entity.fieldHeading &&
                <div className="top-wrapper">
                  <h1 className="title">
                    {item.entity.fieldHeading}
                  </h1>
                </div>
              }

              <div className="media">
                {item.entity.fieldImage &&
                  <Image
                    data={item.entity.fieldImage?.entity?.fieldMediaImage}
                    credit={item.entity.fieldImage?.entity?.fieldCredit}
                    fullPage={item.entity.fieldShowOriginalImage}
                  />
                }
              </div>

              <div className="bottom-wrapper">
                {item.entity.fieldSubtitle &&
                  <h2 className="subtitle">
                    {item.entity.fieldSubtitle}
                  </h2>
                }
                {item.entity.fieldText &&
                  <div
                    className={"text"}
                    dangerouslySetInnerHTML={{ __html: item.entity.fieldText?.value }}
                  />
                }
              </div>
            </div>
          </div>
        )}
      </Slider>
    </section>
  );
};

InforscreenSlider.PropTypes = {
  content: PropTypes.shape({
    __typename: PropTypes.string,
    entityBundle: PropTypes.string,
    entityId: PropTypes.string,
    fieldHeading: PropTypes.string,
  })
}

export default InforscreenSlider;