import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import Image from "../../image/image";
import moment from "moment";
import { withRouter, Link } from "react-router-dom";

import EditButton from "../../../backend/edit-button";
import { teaserEventPropTypes } from "./teaser-event";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserEventTeaserlist extends Component {
  state = {};

  render() {
    return (
      <article
        className={`node node-${this.props.item.entityId} node-teaser teaser-veranstaltung teaser-list`}
      >
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.entityId}
          destinationRoute={this.props.location.pathname}
        />

        <Link
          className="flex-wrap"
          to={
            this.props.item.entityUrl
              ? this.props.item.entityUrl.path
              : this.props.item.path.alias
          }
        >
          <div
            className={classNames({
              row: true,
            })}
          >
            <div
              className={classNames({
                "col-16": true,
              })}
            >
              {this.props.item.fieldBild !== null && (
                <Image
                  data={this.props.item.fieldBild.entity.fieldMediaImage}
                  nodeTitle={this.props.item.title}
                />
              )}
            </div>

            <div
              className={classNames({
                "col-16": true,
              })}
            >
              <div className="row">
                <div className="col-16">
                  <div className="meta-date top-line">
                    <span>
                      {moment
                        .unix(this.props.item.fieldDate.value)
                        .local()
                        .format("ddd")}
                    </span>
                    {moment
                      .unix(this.props.item.fieldDate.value)
                      .local()
                      .format("HH:mm") !== "00:00" && (
                      <span>
                        {moment
                          .unix(this.props.item.fieldDate.value)
                          .local()
                          .format("HH:mm")}{" "}
                        Uhr
                      </span>
                    )}
                    <span>
                      {moment
                        .unix(this.props.item.fieldDate.value)
                        .local()
                        .format("DD.MM.YYYY")}
                    </span>
                    <div className="location">{this.props.item.fieldOrt}</div>
                    {this.props.item.fieldDate.endTime &&
                      !this.props.item.fieldDauerAusblenden && (
                        <div className="duration">
                          Dauer{" "}
                          {Math.round(
                            moment
                              .duration(
                                moment
                                  .utc(this.props.item.fieldDate.endTime)
                                  .local()
                                  .diff(
                                    moment
                                      .unix(this.props.item.fieldDate.value)
                                      .local()
                                  )
                              )
                              .asHours()
                          )}{" "}
                          Std.
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-16">
                  {this.props.item.fieldEventCategory.length > 0 && (
                    <span className="top-line">
                      {this.props.item.fieldEventCategory[0].entity.name}
                    </span>
                  )}
                  <h3>{this.props.item.title}</h3>
                  <div
                    className="text teaser-text d-none d-lg-block"
                    dangerouslySetInnerHTML={{
                      __html: `${this.props.item.fieldText.processed}`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </article>
    );
  }
}

TeaserEventTeaserlist.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserEventPropTypes,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserEventTeaserlist));
