import React from "react";
import { FormattedMessage } from "react-intl";
import LazyLoad from "react-lazyload";
import { restHostBackend, self } from "../config";
import InternalInfo from "./internal-info";
import { Link } from "react-router-dom";
import EventDate from "../event/event-date";

const Content = (props) => {
  // Transform Date Values to use is with eventDate Component.
  let date = {};

  if (props.result.field_date_value) {
    date = {
      fieldDate: {
        value: props.result.field_date_value,
        endValue: props.result.field_date_end_value,
      },
    };
  }

  return (
    <>
      <div className="margin-wrapper">
        {props.result.title_render && (
          <div className="tags">
            <div className="tag type">
              {props.result.type === "person" && (
                <FormattedMessage id="type.person" />
              )}

              {props.result.type === "veranstaltung" && (
                <FormattedMessage id="type.event" />
              )}

              {props.result.type.includes("landingpage") && (
                <FormattedMessage id="type.page" />
              )}

              {props.result.type === "news" && (
                <FormattedMessage id="type.news" />
              )}

              {props.result.type === "course" && (
                <FormattedMessage id="type.course" />
              )}

              {props.result.type === "artist" && (
                <FormattedMessage id="type.artist" />
              )}

              {props.result.type === "ensemble" && (
                <FormattedMessage id="type.ensemble" />
              )}
            </div>
          </div>
        )}
        <div className="title">
          <span
            dangerouslySetInnerHTML={{
              __html:
                props.result.field_displayed_title || props.result.title_render,
            }}
          />
          {props.result.field_date_value && (
            <span className="event-time">
              <EventDate content={date} />
            </span>
          )}
        </div>
      </div>
      <LazyLoad
        offset={200}
        scrollContainer={props.scrollContainer}
        overflow={true}
      >
        {props.result.field_internal ? (
          <InternalInfo redirectUrl={props.redirectUrl} />
        ) : (
          <>
            {props.result.image_1 && (
              <img
                className="hover-image"
                src={props.result.image_1}
                alt={`Ein Bild zum Suchergebnis: ${props.result.title_render}`}
              />
            )}
            {props.result.image_2 && (
              <img
                className="hover-image"
                src={props.result.image_2}
                alt={`Ein Bild zum Suchergebnis: ${props.result.title_render}`}
              />
            )}
            {props.result.image_3 && (
              <img
                className="hover-image"
                src={props.result.image_3}
                alt={`Ein Bild zum Suchergebnis: ${props.result.title_render}`}
              />
            )}
          </>
        )}
      </LazyLoad>
    </>
  );
};

export const SearchResult = (props) => {
  const url = props.result.url.replace(restHostBackend, "").replace(self, "");

  return (
    <>
      {props.result.field_internal ? (
        <div className="internal-no-link">
          <Content
            result={props.result}
            redirectUrl={url}
            scrollContainer={props.scrollContainer}
          />
        </div>
      ) : (
        <Link
          to={url}
          data-title={props.result.title_render}
          className="search-link"
        >
          <Content
            result={props.result}
            scrollContainer={props.scrollContainer}
          />
        </Link>
      )}
    </>
  );
};

export default SearchResult;
