import React, { useRef, useState, useEffect } from "react";
import Image from "../../../image/image";
import Slider from "react-slick";
import GalleryArrow from "../../../icons/gallery-arrow";
import ErrorBoundary from "../../../../error-boundary";
import EmbeddedVideo from "../media-video-audio/embedded-video";
import LocalVideo from "../media-video-audio/local-video";
import classNames from "classnames";
import GalleryIndexInfo from "../../../gallery-index-info";
import CustomHyphenation from "../../../custom-hyphenation";

const SliderComponent = (props) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const updateSlideIndex = (next) => {
    setSlideIndex(next);
  }

  const sliderRef = useRef(null),
    sliderComponentRef = useRef(null);

  const sliderSettingsFull = {
    arrows: true,
    dots: false,
    slidesToShow: 2.5,
    lazyLoad: false,
    variableWidth: true,
    slidesToScroll: 1,
    focusOnSelect: false,
    touchMove: true,
    centerMode: true,
    loop: true,
    nextArrow: <GalleryArrow type={"next"} followMouse={false}/>,
    prevArrow: <GalleryArrow type={"previous"} followMouse={false}/>,
    beforeChange: (current, next) => {
      updateSlideIndex(next);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
          centerMode: true
        },
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1.5,
          centerMode: false
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1.2,
          centerMode: false
        },
      },
    ],
  }

  const sliderSettingsSmall = {
    arrows: false,
    dots: false,
    fade: false,
    slidesToShow: 1,
    lazyLoad: false,
    variableWidth: false,
    variableHeight: false,
    slidesToScroll: 1,
    focusOnSelect: false,
    touchMove: true,
    waitForAnimate: false,
    beforeChange: (current, next) => {
      updateSlideIndex(next);
    },
  }

  useEffect(() => {
    // Set an initialization delay for react-slick scince "onInit" does not work.
    setTimeout(() => {
      let highestCaptionHeight = Math.max(
        ...[...sliderComponentRef?.current.querySelectorAll(".info-wrap")].map(item => item.getBoundingClientRect().height)
      );

      if (!Number.isFinite(highestCaptionHeight)) {
        highestCaptionHeight = 0;
      }

      sliderComponentRef?.current?.style.setProperty('--highest-caption-height', `${highestCaptionHeight}px`);
    }, 750);

  }, [sliderComponentRef]);

  let sliderSettings = props.formatSmall ? sliderSettingsSmall : sliderSettingsFull;

  return (
    <div
      className={classNames({
        "custom-slider-component": true,
        "small": props.formatSmall,
        "standard": !props.formatSmall,
        "has-global-caption": props.globalCaption,
      })}
      ref={sliderComponentRef}
    >
      <div className={"slider-wrapper"}>
        {props.content &&
          <Slider {...sliderSettings} ref={sliderRef}>
            {[...props.content, ...props.content].map((item, index) =>
              <>
                {item.entity?.__typename === "MediaBild" &&
                  <ErrorBoundary>
                    <article className={`media-image ${item.entity.fieldMediaImage.style.height / item.entity.fieldMediaImage.style.width > 1.2 ? 'portrait' : 'landscape'}`} key={index}>
                      <Image
                        data={item.entity.fieldMediaImage}
                        format4To3={props.formatSmall && item.entity.fieldMediaImage.style.height / item.entity.fieldMediaImage.style.width < 1.2}
                        customCaption={props.globalCaption}
                        inSlider={true}
                        credit={item.entity.fieldCredit}
                      />
                    </article>

                    {!props.globalCaption && (item.customCaption || item.entity.fieldBildunterschrift || item.entity.fieldCredit) &&
                      <div className="info-wrap">
                        {(item.customCaption || item.entity.fieldBildunterschrift) &&
                          <span className="caption">
                          {item.customCaption ? (
                            <>
                              {item.customCaption}
                            </>
                          ) : (
                            <>
                              {item.entity.fieldBildunterschrift}
                            </>
                          )}
                        </span>
                        }
                        {item.entity.fieldCredit &&
                          <span className="credit">Foto: {item.entity.fieldCredit}</span>
                        }
                      </div>
                    }
                  </ErrorBoundary>
                }

                {item.entity?.__typename === "MediaVideoEmbed" &&
                  <ErrorBoundary>
                    <React.Fragment key={index}>
                      <EmbeddedVideo content={item.entity}/>
                      {item.customCaption &&
                        <div
                          className={classNames({
                            "caption-wrap info-wrap": true
                          })}
                        >
                          {item.customCaption && (
                            <span className="caption">
                            <CustomHyphenation length={6}>
                              {item.customCaption}
                            </CustomHyphenation>
                            </span>
                          )}
                        </div>
                      }
                    </React.Fragment>
                  </ErrorBoundary>
                }

                {item.entity?.__typename === "MediaVideo" &&
                  <ErrorBoundary>
                    <React.Fragment key={index}>
                      <LocalVideo
                        content={item.entity}
                        thumbnail={item.entity.fieldImage}
                      />
                      {item.customCaption &&
                        <div
                          className={classNames({
                            "caption-wrap info-wrap": true
                          })}
                        >
                          {item.customCaption && (
                            <span className="caption">
                            <CustomHyphenation length={6}>
                              {item.customCaption}
                            </CustomHyphenation>
                          </span>
                          )}
                        </div>
                      }
                    </React.Fragment>
                  </ErrorBoundary>
                }
              </>
            )}
          </Slider>
        }

        {props.formatSmall &&
          <GalleryIndexInfo
            slideIndex={slideIndex}
            content={props.content}
            sliderRef={sliderRef}
          />
        }

      </div>

      {props.globalCaption &&
        <div className="global-caption">
          <div className="container">
            <div className="row">
              <div className="col-16">
                <span className="caption info-wrap">{props.globalCaption}</span>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};
{/*
SliderComponent.propTypes = {
  content: PropTypes.shape({
    __typename: PropTypes.string,
    entityBundle: PropTypes.string,
    entityId: PropTypes.string,
    fieldBildunterschrift: PropTypes.string,
    fieldBilder: PropTypes.array,
    fieldSmall: PropTypes.bool,
    fieldColorscheme: PropTypes.shape({
      __typename: PropTypes.string,
      entity: PropTypes.shape({
        __typename: PropTypes.string,
        filedColorVariable: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
    fieldMultimedia: PropTypes.arrayOf(
      PropTypes.shape({
        __typename: PropTypes.string,
        customCaption: PropTypes.string,
        defaultCaption: PropTypes.bool,
        entity: PropTypes.shape({
          __typename: PropTypes.string,
          fieldBildunterschrift: PropTypes.string,
          fieldCredit: PropTypes.string,
          fieldMediaImage: PropTypes.shape({
            __typename: PropTypes.string,
            alt: PropTypes.string,
            title: PropTypes.string,
            style: PropTypes.shape({
              height: PropTypes.number,
              weight: PropTypes.number,
              url: PropTypes.string,
              __typename: PropTypes.string,
            }),
            format4To3: PropTypes.shape({
              url: PropTypes.string,
              __typename: PropTypes.string,
            }),
          }),
        }),
      }),
    )
  }),
};
*/}

export default SliderComponent;