import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import ParagraphExtendedTeaserOverview from "./../paragraph-extended-teaser-overview";
import teaserNodeQueryFilterTagSortAlphabetically from "../../../../teaser-base/queries/teaser-node-query-tag-filtered-sort-alphabetical.graphql";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
  microSite: reduxStore.appStore.microSite,
});

class ParagraphExtendedTeaserOverviewPersons extends Component {
  render() {
    return (
      <>
        <ParagraphExtendedTeaserOverview
          content={this.props.content}
          nodes={this.props.nodes}
          skipQuery={true}
          filterCloudFilters={["fieldStatus", "fieldSubject", "fieldOrganisation", "fieldInstitute", "fieldFunction"]}
          filterCloudLabels={[
            this.props.intl.formatMessage({
              id: "people.filter.label.fieldStatus",
            }),
            this.props.intl.formatMessage({
              id: "people.filter.label.fieldSubject",
            }),
            this.props.intl.formatMessage({
              id: "people.filter.label.fieldOrganisation",
            }),
            this.props.intl.formatMessage({
              id: "people.filter.label.fieldInstitute",
            }),
            this.props.intl.formatMessage({
              id: "people.filter.label.fieldFunction",
            }),
          ]}
          renderFilterAsSelect={true}
          filterCloudFilterSingle={false}
        />
      </>
    );
  }
}

ParagraphExtendedTeaserOverviewPersons.propTypes = {
  content: PropTypes.shape({
    __typename: PropTypes.string,
    entityBundle: PropTypes.string,
    entityId: PropTypes.string,
    fieldBeitraege: PropTypes.array,
    fieldDarstellung: PropTypes.oneOf(["small_big", "small_highlighted"]),
    fieldElementeProSeite: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldLinkZurUebersicht: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.string,
      }),
    }),
    fieldTypExtended: PropTypes.oneOf([
      "news",
      "person",
      "projekt",
      "veranstaltung",
    ]),
    fieldFilterwolke: PropTypes.bool,
    fieldFilterImText: PropTypes.bool,
    fieldFilterDialogBaum: PropTypes.bool,
    fieldSucheAktivieren: PropTypes.bool,
    fieldManuelleSortierung: PropTypes.bool,
    fieldPagerVerwenden: PropTypes.bool,

    fieldFilterImTextReference: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityBundle: PropTypes.oneOf([
            "filtertext_text",
            "filtertext_filter",
          ]),
          fieldFilterTextText: PropTypes.string,
          fieldFilter: PropTypes.shape({
            entity: PropTypes.shape({
              entityLabel: PropTypes.string,
              entityId: PropTypes.string,
            }),
          }),
        }),
      })
    ),
    fieldFilterDialogBaumReferen: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityId: PropTypes.string,
          entityBundle: PropTypes.oneOf(["filtertext_text", "filteroptionen"]),
          fieldFilterTextText: PropTypes.string,
          fieldFilterMultiple: PropTypes.arrayOf(
            PropTypes.shape({
              targetId: PropTypes.string,
              entity: PropTypes.shape({
                entityLabel: PropTypes.string,
              }),
            })
          ),
        }),
      })
    ),
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.string,
      })
    ),
  }),
  nodes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(
  graphql(teaserNodeQueryFilterTagSortAlphabetically, {
    name: "nodes",
    skip: (props) =>
      !props.content.fieldTypExtended ||
      props.content.fieldManuelleSortierung ||
      props.skipQuery,
    options: (props) => ({
      variables: {
        limit: 10000,
        type: [props.content.fieldTypExtended],
        tag: props.content.fieldSchlagwort.map((item) =>
          item.targetId.toString()
        ),
        filterTagEnabled: props.content.fieldSchlagwort.length > 0,
        microSiteFilterEnabled: true,
        microSiteFilter: props.microSite ? props.microSite : "hmdk",
        language: props.currentLanguage.toUpperCase(),
      },
    }),
  })(injectIntl(ParagraphExtendedTeaserOverviewPersons))
);
