import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SocialMediaInfo from "./social-media-info";
import {LinkPropType} from "../../../link/link";
import VisibilitySensor from "react-visibility-sensor";

export const ParagraphSocialMediaInfo = ({ content }) => {
  return(
    <VisibilitySensor
      partialVisibility={true}
      offset={{
        top: typeof window !== "undefined" && !!window ? -(window.innerHeight * 0.75) : -500,
        bottom: typeof window !== "undefined" && !!window ? window.innerHeight * 0.75 : 500
      }}
    >
      {({ isVisible }) => (
        <section
          className="paragraph paragraph-social-media-info"
          data-is-visible={isVisible}
        >
          <div className="container">
            <div className="row">
              <div className="col-16 col-lg-10 offset-lg-3 col-xl-6 offset-xl-5">
                <SocialMediaInfo content={content}/>
              </div>
            </div>
          </div>
        </section>
      )}
    </VisibilitySensor>
  );
};

ParagraphSocialMediaInfo.PropTypes = {
  content: PropTypes.shape({
    __typename: PropTypes.string,
    entityBundle: PropTypes.string,
    entityId: PropTypes.string,
    fieldSocialMediaTitle: PropTypes.string,
    fieldSocialMediaLinks: LinkPropType
  })
}

export default ParagraphSocialMediaInfo;