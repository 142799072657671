import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage, useFormikContext } from "formik";
import classNames from "classnames";

import { states } from "../states";

const RadioButtonField = ({ item }) => {
  const { values, handleChange, handleBlur } = useFormikContext();

  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    values
  );

  return (
    <div
      className={classNames({
        "form-group": true,
        hidden: invisible || !visible,
      })}
      style={item.flex ? { flex: item.flex } : {}}
    >
      <label htmlFor={item.id}>
        {item.title}
        {(!!item.required || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>
      {item.options.map((el) => {
        return (
          <div key={el.value} className="form-check">
            <Field
              className="form-check-input"
              type="radio"
              name={item.id}
              id={el.value}
              onChange={handleChange}
              onBlur={handleBlur}
              value={el.value}
              required={(!!item.required || required) && !optional && visible}
              disabled={!enabled || disabled}
            />
            <label className="form-check-label" htmlFor={el.value}>
              {el.title}
            </label>
          </div>
        );
      })}
      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />
      {!!item.description && (
        <small
          className="form-description text-muted form-text"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      )}
    </div>
  );
};

RadioButtonField.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    states: PropTypes.array,
    description: PropTypes.string,
    flex: PropTypes.number,
    required: PropTypes.object,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        title: PropTypes.string,
      })
    ),
  }),
};

export default RadioButtonField;
