import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import LocalVideo from "./local-video";
import EmbeddedVideo from "./embedded-video";
import ErrorBoundary from "../../../../error-boundary";

/**
 * Render YouTube, Vimeo or local video files.
 */
class ParagraphMedia extends Component {
  render() {
    const sectionClassNames = classNames({
        "paragraph paragraph-media": true,
        "full-bleed": content?.fieldGroesse === "0",
      }),
      { content } = this.props;

    let video = null;


    if (typeof content.fieldVideoFile.entity.fieldMediaOembedVideoEmbed !== "undefined") {
      video = (
        <ErrorBoundary>
          <EmbeddedVideo
            content={content.fieldVideoFile.entity}
            caption={content.fieldCaption}
            thumbnail={content.fieldImage}
            fullSize={content.fieldGroesse === "0"}
          />
        </ErrorBoundary>
      );
    } else {
      video = (
        <ErrorBoundary>
          <LocalVideo
            content={content.fieldVideoFile.entity}
            caption={content.fieldCaption}
            sectionClassNames={sectionClassNames}
            thumbnail={content.fieldImage}
            fullSize={content.fieldGroesse === "0"}
          />
        </ErrorBoundary>
      );
    }
    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldGroesse === "0" && (
          <div className="full-width">
            {video}
          </div>
        )}
        {this.props.content.fieldGroesse === "1" && (
          <div className="container">
            <div className="row">
              <div className="col-16 col-lg-12 offset-lg-2">
                {video}
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

ParagraphMedia.propTypes = {
  content: PropTypes.shape({
    /**
     * 0 = 100%, 1 = 50%
     */
    fieldGroesse: PropTypes.oneOf(["0", "1"]),
    fieldVimeoVideoUrl: PropTypes.string,
    fieldYoutubeVideoUrl: PropTypes.shape({
      videoId: PropTypes.string,
    }),
    fieldImage: PropTypes.shape({
      __typename: PropTypes.string,
      entity: PropTypes.shape({
        __typename: PropTypes.string,
        fieldCredit: PropTypes.string,
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            __typename: PropTypes.string,
            url: PropTypes.string,
          }),
        }),
      })
    }),
    fieldCaption: PropTypes.string,
    __typename: PropTypes.string,
    entityId: PropTypes.string,
    entityBundle: PropTypes.string,
    fieldVideoFile: PropTypes.shape({
      __typename: PropTypes.string,
      entity: PropTypes.shape({
        __typename: PropTypes.string,
        fieldMediaVideoFile: PropTypes.shape({
          __typename: PropTypes.string,
          entity: PropTypes.shape({
            __typename: PropTypes.string,
            url: PropTypes.string,
          }),
        }),
      }),
    }),
  }),
};

export default ParagraphMedia;
