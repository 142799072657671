import React, { useRef } from "react";
import {FormattedMessage} from "react-intl";
import getMailQuery from "get-mail.graphql";
import {withApollo} from "@apollo/client/react/hoc";

const WriteMail = ({ content, client, type } ) => {

  const url = content?.path?.alias || content?.entityUrl?.path;

  const getEMail = () => {
    client.query({
      query: getMailQuery,
      variables: { path: url }
    }).then(response => {
      // Open Mailto Link in new Tab, open in same tab when popups are blocked.
      window.open(`mailto:${response?.data?.route?.entity?.fieldMail}`, '_blank') || window.open(`mailto:${response?.data?.route?.entity?.fieldMail}`);
    });
  }

  // Only Show if window is defined, mail is set and url is known
  if (content?.fieldHasMail && url && !!typeof window) {

    return (
      <button
        className="write-mail"
        onClick={() => getEMail()}
      >
        {type === "write-me" ? (
          <FormattedMessage id="contact.mail_me" />
        ) : (
          <FormattedMessage id="contact.write_email" />
        )}
      </button>
    );
  }
};

export default withApollo(WriteMail);