import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage, useFormikContext } from "formik";
import classNames from "classnames";

import { states } from "../states";
import { getValueFromStringObjPath } from "../webform-elements";

const SelectField = ({ item, compositeIndex, compositeParent }) => {
  const { values, handleChange, handleBlur } = useFormikContext();

  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    values
  );

  const value = compositeParent
    ? compositeIndex
      ? getValueFromStringObjPath(values, compositeParent)[compositeIndex][
          item.id
        ]
      : getValueFromStringObjPath(values, compositeParent)[item.id]
    : values[item.id];
  const identifier = compositeParent
    ? compositeIndex
      ? `${compositeParent}[${compositeIndex}][${item.id}]`
      : `${compositeParent}[${item.id}]`
    : item.id;

  return (
    <div
      className={classNames({
        "form-group": true,
        hidden: invisible || !visible,
      })}
      style={item.flex ? { flex: item.flex } : {}}
    >
      <label htmlFor={identifier}>
        {item.title}
        {(!!item.required || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>

      <Field
        id={identifier}
        name={identifier}
        as="select"
        className="form-control"
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        required={(!!item.required || required) && !optional && visible}
        disabled={!enabled || disabled}
        multiple={!!item.multiple}
      >
        <option
          disabled={(!!item.required || required) && !optional && visible}
          value=""
        >
          {" "}
          - Bitte auswählen -{" "}
        </option>
        {item.options.map((el, i) => (
          <option key={i} value={el.value}>
            {el.title}
          </option>
        ))}
      </Field>

      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />

      {!!item.description && (
        <small
          className="form-description text-muted form-text"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      )}
    </div>
  );
};

SelectField.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    states: PropTypes.object,
    flex: PropTypes.number,
    title: PropTypes.string,
    required: PropTypes.object,
    description: PropTypes.string,
    multiple: PropTypes.shape({
      limit: PropTypes.number,
      message: PropTypes.string,
    }),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  compositeIndex: PropTypes.number,
  compositeParent: PropTypes.string,
};

export default SelectField;
