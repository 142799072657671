import React from "react";
import { useParams } from "react-router-dom";

const NewsletterConfirm = () => {
  const { action, status } = useParams();

  const messages = {
    subscribe: {
      success: {
        headline: "Anmeldung erfolgreich",
        body: "Sie wurden erfolgreich zum Newsletter angemeldet.",
      },
      error: {
        headline: "Anmeldung fehlgeschlagen",
        body: "Es ist ein Fehler aufgetreten.",
      },
    },
    unsubscribe: {
      success: {
        headline: "Abmeldung erfolgreich",
        body: "Sie wurden erfolgreich vom Newsletter abgemeldet.",
      },
      error: {
        headline: "Abmeldung fehlgeschlagen",
        body: "Es ist ein Fehler aufgetreten.",
      },
    },
  };

  return (
    <article className="newsletter-confirm">
      <section className="content-wrap">
        <section className="paragraph">
          <div className="container">
            <div className="row">
              <div className="col-16">
                <h1>{messages[action][status]?.headline}</h1>
                <div className="text">
                  <p>{messages[action][status]?.body}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </article>
  );
};

export default NewsletterConfirm;
