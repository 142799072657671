import { Route, Switch, useLocation } from "react-router-dom";
import LoginForm from "./intern/login-form";
import ForgotPasswordForm from "./intern/forgot-password-form";
import ResetPasswordWithTempPasswordForm from "./intern/reset-password-with-temp-password-form";
import Landingpage from "./landingpage/landingpage";
import Course from "./course/course";
import News from "./news/news";
import Event from "./event/event";
import Person from "./person/person";
import NodeRevision from "./backend/NodeRevision";
import Error404 from "./error-404";
import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import NewsletterConfirm from "./general-components/content-base/paragraphs/newsletter/newsletter-confirm";
import Infoscreen from "./infoscreen/infoscreen";

const AppSwitch = ({ location }) => {
  const pageTransition = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.5 },
  };

  return (
    <AnimatePresence mode="wait">
      <Switch location={location} key={location?.pathname}>
        {/* Internal Pages */}
        <Route exact path="/intern/login">
          <motion.div {...pageTransition}>
            <LoginForm location={location} />
          </motion.div>
        </Route>
        <Route exact path="/intern/forgot-password">
          <motion.div {...pageTransition}>
            <ForgotPasswordForm location={location} />
          </motion.div>
        </Route>
        <Route exact path="/intern/reset-pass/temp">
          <motion.div {...pageTransition}>
            <ResetPasswordWithTempPasswordForm location={location} />
          </motion.div>
        </Route>

        {/* Public Pages */}
        <Route exact path="/:language?/projekt/:alias">
          <motion.div {...pageTransition}>
            <Landingpage location={location} />
          </motion.div>
        </Route>

        <Route exact path="/:language?/studiengang/:alias">
          <motion.div {...pageTransition}>
            <Course location={location} />
          </motion.div>
        </Route>

        <Route exact path="/:language?/course/:alias">
          <motion.div {...pageTransition}>
            <Course location={location} />
          </motion.div>
        </Route>

        <Route exact path="/:language?/news/:alias">
          <motion.div {...pageTransition}>
            <News location={location} />
          </motion.div>
        </Route>

        <Route exact path="/:language?/veranstaltung/:alias">
          <motion.div {...pageTransition}>
            <Event location={location} />
          </motion.div>
        </Route>

        <Route exact path="/:language?/kuenstler-in/:alias">
          <motion.div {...pageTransition}>
            <Person location={location} />
          </motion.div>
        </Route>

        <Route exact path="/:language?/ensemble/:alias">
          <motion.div {...pageTransition}>
            <Person location={location} />
          </motion.div>
        </Route>

        <Route exact path="/:language?/artist/:alias">
          <motion.div {...pageTransition}>
            <Person location={location} />
          </motion.div>
        </Route>

        <Route exact path="/:language?/person/:alias">
          <motion.div {...pageTransition}>
            <Person location={location} />
          </motion.div>
        </Route>

        <Route exact path="/:language?/infoscreen/:alias">
          <motion.div {...pageTransition}>
            <Infoscreen location={location} />
          </motion.div>
        </Route>

        <Route exact path="/node/:nodeId/revisions/:revisionId/view">
          <motion.div {...pageTransition}>
            <NodeRevision location={location} />
          </motion.div>
        </Route>

        <Route exact path="/">
          <motion.div {...pageTransition}>
            <Landingpage location={location} />
          </motion.div>
        </Route>

        <Route exact path="/:language?/:alias">
          <motion.div {...pageTransition}>
            <Landingpage location={location} />
          </motion.div>
        </Route>

        <Route
          exact
          path="/newsletter/confirm/:action/:status"
          component={NewsletterConfirm}
        />

        {/* Catch-all for 404 */}
        <Route path="*">
          <motion.div {...pageTransition}>
            <Error404 location={location} />
          </motion.div>
        </Route>
      </Switch>
    </AnimatePresence>
  );
};

export default AppSwitch;
