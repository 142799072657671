import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Download from "./../../../icons/download";
import Image from "../../../image/image";
import {self} from "../../../../config";
import CustomHyphenation from "../../../custom-hyphenation";

class ParagraphDownload extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-download": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          {this.props.content.fieldDatei && this.props.content.fieldDatei.map((item, index) =>
            <div className="row download-element">
              <div className="offset-element d-none d-lg-flex col-1 offset-1" />
              <div className="border-element d-none d-lg-flex col-1" />
              {item.entity.fieldVorschaubild !==
                null && (
                  <div className="col-auto col-md-3 col-lg-2 image-wrapper">
                    <Image
                      data={
                        item.entity.fieldVorschaubild
                          .entity.fieldMediaImage
                      }
                    />
                  </div>
                )}
              <div
                className={classNames({
                  "info-wrapper": true,
                  "col-13 col-lg-10":
                    item.entity.fieldVorschaubild ===
                    null,
                  "col-10 col-lg-8":
                    item.entity.fieldVorschaubild !==
                    null,
                })}
              >
                <h3>
                  <CustomHyphenation
                    length={12}
                  >
                    {item.entity.entityLabel}
                  </CustomHyphenation>
                </h3>
                {item.entity.fieldBeschreibung && (
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html:
                      item.entity.fieldBeschreibung
                        .processed,
                    }}
                  />
                )}
              </div>
              <div className="col-3 col-lg-1 d-flex icon-col">
                <a
                  className="download-icon"
                  href={
                    item.entity.fieldMediaFile.entity
                      .url
                  }
                  download={true}
                >
                  <span className="sr-only">
                    download
                  </span>
                  <Download/>
                </a>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

ParagraphDownload.propTypes = {
  content: PropTypes.shape({
    __typename: PropTypes.string,
    fieldColorScheme: PropTypes.shape({
      __typename: PropTypes.string,
      entity: PropTypes.shape({
        __typename: PropTypes.string,
        fieldColorVariable: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
    entityId: PropTypes.string,
    entityBundle: PropTypes.string,
    fieldDatei: PropTypes.arrayOf(
      PropTypes.shape({
        __typename: PropTypes.string,
        entity: PropTypes.shape({
          __typename: PropTypes.string,
          fieldVorschaubild: PropTypes.shape({
            entity: PropTypes.shape({
              fieldMediaImage: PropTypes.shape({
                alt: PropTypes.string,
                title: PropTypes.string,
                style: PropTypes.shape({
                  url: PropTypes.string,
                }),
              }),
            }),
          }),
          entityLabel: PropTypes.string.isRequired,
          fieldBeschreibung: PropTypes.shape({
            processed: PropTypes.string,
          }),
          fieldMediaFile: PropTypes.shape({
            __typename: PropTypes.string,
            entity: PropTypes.shape({
              __typename: PropTypes.string,
              url: PropTypes.string,
            }),
          }),
        }),
      }),
    ),
  }),
};

export default ParagraphDownload;
