import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Slider from "react-slick";
import QuoteContent from "../zitat/quote-content";
import GalleryIndexInfo  from "../../../gallery-index-info";

class ParagraphQuoteCarousel extends Component {
  slider = React.createRef();

  state = {
    slideIndex: 0,
    sliderSettings: {
      adaptiveHeight: false,
      arrows: true,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchMove: true,
      beforeChange: (current, next) => {
        this.setState({ slideIndex: next });
      },
    }
  };

  render() {
    const sectionClassNames = classNames({
      'paragraph paragraph-quote-carousel': true
    });

    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldQuotes?.length > 1 ? (
          <>
            <Slider {...this.state.sliderSettings} ref={this.slider}>
              {this.props.content.fieldQuotes && this.props.content.fieldQuotes.map((item, index) =>
                <div className="slide" key={index}>
                  <div className="container">
                    <div className="row">
                      <QuoteContent
                        content={item.entity}
                        inCarousel={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </Slider>
            <GalleryIndexInfo
              slideIndex={this.state.slideIndex}
              content={this.props.content.fieldQuotes}
              sliderRef={this.slider}
            />
          </>
        ) : (
          <QuoteContent
            content={this.props.content.fieldQuotes[0].entity}
            inCarousel={false}
          />
        )}
      </section>
    );
  }
}

ParagraphQuoteCarousel.propTypes = {
  content: PropTypes.shape({
    __typename: PropTypes.string,
    entityBundle: PropTypes.string,
    entityId: PropTypes.string,
    fieldColorScheme: PropTypes.shape({
      __typename: PropTypes.string,
      entity: PropTypes.shape({
        __typename: PropTypes.string,
        fieldColorVariable: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
    fieldQuotes: PropTypes.arrayOf(
      PropTypes.shape({
        __typename: PropTypes.string,
        entity: PropTypes.shape({
          __typename: PropTypes.string,
          fieldQuelle: PropTypes.string,
          fieldZitat: PropTypes.string,
          fieldImage: PropTypes.shape({
            __typename: PropTypes.string,
            entity: PropTypes.shape({
              __typename: PropTypes.string,
              fieldCredit: PropTypes.string,
              fieldMediaImage: PropTypes.shape({
                alt: PropTypes.string,
                title: PropTypes.string,
                style: PropTypes.shape({
                  __typename: PropTypes.string,
                  url: PropTypes.string,
                }),
              }),
            })
          })
        }),
      }),
    )
  }),
};

export default ParagraphQuoteCarousel;