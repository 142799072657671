import React, { useEffect, useRef } from "react";

const CheckForChildren = ({ as: ElementType = "div", classes, children }) => {
  const checkForChildrenWrapper = useRef();

  const supportsHasPseudoClass = () => {
    try {
      const style = document.createElement('style');
      document.head.appendChild(style);

      // Attempt to insert a rule using :has
      style.sheet.insertRule(':has(*) {}', 0);

      // If the rule is inserted successfully, the browser supports :has
      document.head.removeChild(style);
      return true;
    } catch (e) {
      // If an error is thrown, the browser does not support :has
      return false;
    }
  }

  useEffect(() => {
    // Usage
    if (!supportsHasPseudoClass()) {
      if (checkForChildrenWrapper.current.innerHTML === "") {
        checkForChildrenWrapper.current.style.display = "none";
      }
    }
  }, []);

  return (
    <ElementType
      className={classes}
      ref={checkForChildrenWrapper}
      data-check-for-children={true}
    >
      {children}
    </ElementType>
  );
}

export default CheckForChildren;
