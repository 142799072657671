import React, { useRef, useState, useEffect } from "react";
import {FormattedMessage} from "react-intl";
import TeaserEventList
  from "../general-components/content-base/paragraphs/teaserliste/events/component-teaser-event-list";

const EventSeries = ({ content }) => {
  const [showList, setShowList] = useState(true);

  const checkQuery = (nodeCount) => {
    if (nodeCount === 0) {
      setShowList(false)
    }
  }

  // Show Event Series List only, if there are other Events in the Event Series
  if (showList) {
    return (
      <section className={"paragraph event-series-list module-wrapper"}>
        <div className="container">
          <div className="row">
            <div className="col-16 col-lg-10 offset-lg-3">
              <h3>
                <FormattedMessage id="more_events"/>
              </h3>
              <TeaserEventList
                nodesConfig={"Automatisch (chronologisch)"}
                type={"veranstaltung"}
                tags={[]}
                eventSeries={content.fieldEventSeries?.entity.entityId}
                eventId={content.entityId}
                onQueryLoaded={checkQuery}
                futureEventsOnly={true}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default EventSeries;